import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import * as settings from '../settings/settings';

class Error extends React.Component {
  render() {
    return (
      <div className="error">
        <Helmet>
          <title>{ settings.PROJECTNAME } - 錯誤</title>
        </Helmet>
        <p className="center">頁面不存在，<Link to="/">按此</Link>返回首頁。</p>
      </div>
    );
  }
}

export default Error;
