export const GETUSERDATA = "GETUSERDATA";
export const ADDTOCART = "ADDTOCART";
export const REMOVEFROMCART = "REMOVEFROMCART";
export const EMPTYCART = "EMPTYCART";
export const GETSITENOTES = "GETSITENOTES";
export const GETSSTYPES = "GETSSTYPES";
export const GETSSYEARS = "GETSSYEARS";
export const GETSSTIMES = "GETSSTIMES";
export const GETSSGRADES = "GETSSGRADES";
export const GETSSREGIONS = "GETSSREGIONS";
export const GETSSFORMATS = "GETSSFORMATS";
export const GETSSOTHERS = "GETSSOTHERS";
export const SETSSLOADED = "SETSSLOADED";
