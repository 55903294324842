import React from 'react';
import { Link } from 'react-router-dom';

import * as settings from '../settings/settings';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer center">
        <p>聯絡信箱：<a href={ "mailto:" + settings.CONTACT_EMAIL } target="_blank">{ settings.CONTACT_EMAIL }</a></p>
        <p>&copy; {new Date().getFullYear()} <Link to="/">{ settings.PROJECTNAME }</Link> hosted on <a href="https://eecsmt.com" target="_blank">電資人</a></p>
      </div>
    );
  }
}

export default Footer;
