import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import axios from 'axios';
import InnerHTML from 'dangerously-set-html-content';

import { Button } from 'react-bootstrap';

import { addToCart, removeFromCart } from '../actions/userAction';
import { getSiteNotes } from '../actions/siteAction';
import { getSSTypes, getSSYears, getSSTimes, getSSGrades, getSSRegions, getSSFormats, getSSOthers, setSSLoaded } from '../actions/ssAction';

import * as settings from '../settings/settings';
import loadingGif from '../assets/loading.gif';
import examPng from '../assets/exam.png';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      type: "",
      year: "",
      time: "",
      grade: "",
      region: "",
      format: "",
      other: "",
      searchResults: "",
      buttonDisabled: false
    };
  }

  componentDidMount() {
    this.props.dispatch(getSiteNotes());
    this.props.dispatch(getSSTypes());
    this.props.dispatch(getSSYears());
    this.props.dispatch(getSSTimes());
    this.props.dispatch(getSSGrades());
    this.props.dispatch(getSSRegions());
    this.props.dispatch(getSSFormats());
    this.props.dispatch(getSSOthers());
    this.props.dispatch(setSSLoaded());
  }

  componentDidUpdate() {
    if(this.state.isLoading && !this.props.user.isLoading && !this.props.site.isLoading && !this.props.ss.isLoading) {
      this.setState({ isLoading: false });
    }
  }

  showTypes = () => {
    const { types } = this.props.ss;
    if(typeof types === "string") {
      return (
        <React.Fragment>
          <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button>
        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          { types.map(type => (
            <React.Fragment>
              <input type="radio" className="btn-check" name="types" value={ type._id } id={ type._id } checked={ this.state.type === type._id } onChange={ (e) => this.onTypeChange(e) } autoComplete="off" />
              <label className="btn btn-outline-secondary btn-sm" htmlFor={ type._id }>{ type.name }</label>
            </React.Fragment>
          )) }
        </React.Fragment>
      )
    }
  }

  showYears = () => {
    const { years } = this.props.ss;
    if(typeof years === "string") {
      return (
        <React.Fragment>
          <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button>
        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          { years.map(year => (
            <React.Fragment>
              <input type="radio" className="btn-check" name="years" value={ year._id } id={ year._id } checked={ this.state.year === year._id } onChange={ (e) => this.onYearChange(e) } autoComplete="off" />
              <label className="btn btn-outline-secondary btn-sm" htmlFor={ year._id }>{ year.name }</label>
            </React.Fragment>
          )) }
        </React.Fragment>
      )
    }
  }

  showTimes = () => {
    const { times } = this.props.ss;
    if(typeof times === "string") {
      return (
        <React.Fragment>
          <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button>
        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          { times.map(time => (
            <React.Fragment>
              <input type="radio" className="btn-check" name="times" value={ time._id } id={ time._id } checked={ this.state.time === time._id } onChange={ (e) => this.onTimeChange(e) } autoComplete="off" />
              <label className="btn btn-outline-secondary btn-sm" htmlFor={ time._id }>{ time.name }</label>
            </React.Fragment>
          )) }
        </React.Fragment>
      )
    }
  }

  showGrades = () => {
    const { grades } = this.props.ss;
    if(typeof grades === "string") {
      return (
        <React.Fragment>
          <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button>
        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          { grades.map(grade => (
            <React.Fragment>
              <input type="radio" className="btn-check" name="grades" value={ grade._id } id={ grade._id } checked={ this.state.grade === grade._id } onChange={ (e) => this.onGradeChange(e) } autoComplete="off" />
              <label className="btn btn-outline-secondary btn-sm" htmlFor={ grade._id }>{ grade.name }</label>
            </React.Fragment>
          )) }
        </React.Fragment>
      )
    }
  }

  showRegions = () => {
    const { regions } = this.props.ss;
    if(typeof regions === "string") {
      return (
        <React.Fragment>
          <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button>
        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          { regions.map(region => (
            <React.Fragment>
              <input type="radio" className="btn-check" name="regions" value={ region._id } id={ region._id } checked={ this.state.region === region._id } onChange={ (e) => this.onRegionChange(e) } autoComplete="off" />
              <label className="btn btn-outline-secondary btn-sm" htmlFor={ region._id }>{ region.name }</label>
            </React.Fragment>
          )) }
        </React.Fragment>
      )
    }
  }

  showFormats = () => {
    const { formats } = this.props.ss;
    if(typeof formats === "string") {
      return (
        <React.Fragment>
          <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button>
        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          { formats.map(format => (
            <React.Fragment>
              <input type="radio" className="btn-check" name="formats" value={ format._id } id={ format._id } checked={ this.state.format === format._id } onChange={ (e) => this.onFormatChange(e) } autoComplete="off" />
              <label className="btn btn-outline-secondary btn-sm" htmlFor={ format._id }>{ format.name }</label>
            </React.Fragment>
          )) }
        </React.Fragment>
      )
    }
  }

  showOthers = () => {
    const { others } = this.props.ss;
    if(typeof others === "string") {
      return (
        <React.Fragment>
          <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button>
        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          { others.map(other => (
            <React.Fragment>
              <input type="radio" className="btn-check" name="others" value={ other._id } id={ other._id } checked={ this.state.other === other._id } onChange={ (e) => this.onOtherChange(e) } autoComplete="off" />
              <label className="btn btn-outline-secondary btn-sm" htmlFor={ other._id }>{ other.name }</label>
            </React.Fragment>
        )) }
        </React.Fragment>
      )
    }
  }

  onTypeChange = (e) => {
    this.setState({ type: e.target.value });
  }

  onYearChange = (e) => {
    this.setState({ year: e.target.value });
  }

  onTimeChange = (e) => {
    this.setState({ time: e.target.value });
  }

  onGradeChange = (e) => {
    this.setState({ grade: e.target.value });
  }

  onRegionChange = (e) => {
    this.setState({ region: e.target.value });
  }

  onFormatChange = (e) => {
    this.setState({ format: e.target.value });
  }

  onOtherChange = (e) => {
    this.setState({ other: e.target.value });
  }

  searchSS = () => {
    this.setState({ buttonDisabled: true });
    setTimeout(() => this.setState({ buttonDisabled: false }), 3000);
    const params = {};
    if(this.state.type.length > 0) params.type = this.state.type;
    if(this.state.year.length > 0) params.year = this.state.year;
    if(this.state.time.length > 0) params.time = this.state.time;
    if(this.state.grade.length > 0) params.grade = this.state.grade;
    if(this.state.region.length > 0) params.region = this.state.region;
    if(this.state.format.length > 0) params.format = this.state.format;
    if(this.state.other.length > 0) params.other = this.state.other;
    const options = {
      url: "/api/file/getfiles",
      method: "GET",
      params: params,
      withCredentials: true
    };
    axios(options)
      .then((res) => this.setState({ searchResults: res.data }))
      .catch((err) => this.setState({ searchResults: err.response.data }));
  }

  resetSS = () => {
    this.setState({
      type: "",
      year: "",
      time: "",
      grade: "",
      region: "",
      format: "",
      other: ""
    });
  }

  searchContent = () => {
    const { searchResults } = this.state;
    if(typeof searchResults === "string") {
      return (
        <React.Fragment>
          <p className="center">查無資料</p>
        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>
          { searchResults.map(res => (
            <React.Fragment>
              <table className="no-border" style={{ height: 150, width: "100%" }}>
                <tbody>
                  <tr style={{ height: 50 }}>
                    <td rowSpan="3" height="150" width="150"><img src={ examPng } alt="exam" width="140" height="140" /></td>
                    <td colSpan="10"><h4>{ res.title }</h4></td>
                    <td rowSpan="3" height="150" width="150" style={{ textAlign: "center" }}>
                      { this.props.user.isLoggedIn ? (this.props.ss.formats.find(({ _id }) => _id === res.format) ? this.showBuyButton(res._id) : "無法購買") : "僅供會員購買" }
                    </td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    <td style={{ width: 150 }}><b>類型：</b>{ this.props.ss.types.find(({ _id }) => _id === res.type) ? this.props.ss.types.find(({ _id }) => _id === res.type).name : "無法讀取" }</td>
                    <td style={{ width: 150 }}><b>格式：</b>{ this.props.ss.formats.find(({ _id }) => _id === res.format) ? this.props.ss.formats.find(({ _id }) => _id === res.format).name : "無法讀取" }</td>
                    <td style={{ width: 150 }}><b>價格：</b>{ this.props.ss.formats.find(({ _id }) => _id === res.format) ? this.props.ss.formats.find(({ _id }) => _id === res.format).price : "無法讀取" }元</td>
                  </tr>
                  <tr style={{ height: 50 }}>
                    { res.year.length > 1 ? <td style={{ width: 150 }}><b>年度：</b>{ this.props.ss.years.find(({ _id }) => _id === res.year) ? this.props.ss.years.find(({ _id }) => _id === res.year).name : "無法讀取" }</td> : null }
                    { res.time.length > 1 ? <td style={{ width: 150 }}><b>時段：</b>{ this.props.ss.times.find(({ _id }) => _id === res.time) ? this.props.ss.times.find(({ _id }) => _id === res.time).name : "無法讀取" }</td> : null }
                    { res.grade.length > 1 ? <td style={{ width: 150 }}><b>年級：</b>{ this.props.ss.grades.find(({ _id }) => _id === res.grade) ? this.props.ss.grades.find(({ _id }) => _id === res.grade).name : "無法讀取" }</td> : null }
                  </tr>
                </tbody>
              </table>
              <hr className="m0" />
            </React.Fragment>
          )) }
          <p className="center footer bg-lblue">共{ searchResults.length }筆資料</p>
        </React.Fragment>
      );
    }
  }

  showBuyButton = (id) => {
    const { data } = this.props.user;
    if(data.bought.indexOf(id) > -1) {
      return (
        <Button variant="outline-success" size="sm" disabled={ true }>已購買</Button>
      );
    }
    if(data.cart.indexOf(id) === -1) {
      return (
        <Button variant="outline-primary" size="sm" onClick={ () => this.addItemToCart(id) }>加入購物車</Button>
      );
    }
    else {
      return (
        <Button variant="outline-danger" size="sm" onClick={ () => this.removeItemFromCart(id) }>從購物車移除</Button>
      );
    }
  }

  addItemToCart = (id) => {
    this.props.dispatch(addToCart(id));
  }

  removeItemFromCart = (id) => {
    this.props.dispatch(removeFromCart(id));
  }

  render() {
    return (
      <div className="home">
        <Helmet>
          <title>{ settings.PROJECTNAME } - 首頁</title>
        </Helmet>
        <div className="left inline-block w20 vtop">
          <div className="notes">
            <p className="center title bg-yellow">公告</p>
            <hr className="m0" />
            { this.state.isLoading ? <p className="center"><img src={ loadingGif } alt="loading" /></p> : <p>{ <InnerHTML html={ this.props.site.notes } /> }</p> }
          </div>
          <div class="donate center">
            <p class="title bg-yellow">贊助</p>
            <hr class="m0" />
            <a href="https://p.ecpay.com.tw/937BAB4"><img src="https://payment.ecpay.com.tw/Upload/QRCode/202202/QRCode_b8632274-35a7-4ca7-9da5-c5ad804733b7.png" alt="ecpay" width="90%" /></a>
          </div>
        </div>
        <div className="right inline-block w80">
          <div className="select-search">
            <p className="center title bg-lblue">篩選條件（不選即可搜尋所有試題）</p>
            <hr className="m0" />
            <p>類型：
              { this.state.isLoading ? <img src={ loadingGif } alt="loading" /> : this.showTypes() }
            </p>
            <p>年度：
              { this.state.isLoading ? <img src={ loadingGif } alt="loading" /> : this.showYears() }
            </p>
            <p>時段：
              { this.state.isLoading ? <img src={ loadingGif } alt="loading" /> : this.showTimes() }
            </p>
            <p>年級：
              { this.state.isLoading ? <img src={ loadingGif } alt="loading" /> : this.showGrades() }
            </p>
            <p>地區：
              { this.state.isLoading ? <img src={ loadingGif } alt="loading" /> : this.showRegions() }
            </p>
            <p>格式：
              { this.state.isLoading ? <img src={ loadingGif } alt="loading" /> : this.showFormats() }
            </p>
            <p>其他：
              { this.state.isLoading ? <img src={ loadingGif } alt="loading" /> : this.showOthers() }
            </p>
            <hr className="m0" />
            <p className="center">
              <Button variant="outline-primary" size="sm" onClick={ () => this.searchSS() } disabled={ this.state.buttonDisabled }>{ this.state.buttonDisabled ? "請稍候" : "搜尋" }</Button>
              <Button variant="outline-danger" size="sm" onClick={ () => this.resetSS() }>清除</Button>
            </p>
          </div>
          <div className="search-results mt16">
            <p className="center title bg-lblue">搜尋結果</p>
            <hr className="m0" />
            { this.state.isLoading ? <img src={ loadingGif } alt="loading" /> : this.searchContent() }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    site: state.site,
    ss: state.ss
  };
}

export default connect(mapStateToProps)(Home);
