import axios from 'axios';

import * as act from './index';

export const getSSTypes = () => (dispatch) => {
  const options = {
    url: "/api/ss/gettypes",
    method: "GET",
    withCredentials: true
  };
  axios(options)
    .then((res) => res.data)
    .then((res) =>
      dispatch({
        type: act.GETSSTYPES,
        payload: res
      })
    );
};

export const getSSYears = () => (dispatch) => {
  const options = {
    url: "/api/ss/getyears",
    method: "GET",
    withCredentials: true
  };
  axios(options)
    .then((res) => res.data)
    .then((res) =>
      dispatch({
        type: act.GETSSYEARS,
        payload: res
      })
    );
};

export const getSSTimes = () => (dispatch) => {
  const options = {
    url: "/api/ss/gettimes",
    method: "GET",
    withCredentials: true
  };
  axios(options)
    .then((res) => res.data)
    .then((res) =>
      dispatch({
        type: act.GETSSTIMES,
        payload: res
      })
    );
};

export const getSSGrades = () => (dispatch) => {
  const options = {
    url: "/api/ss/getgrades",
    method: "GET",
    withCredentials: true
  };
  axios(options)
    .then((res) => res.data)
    .then((res) =>
      dispatch({
        type: act.GETSSGRADES,
        payload: res
      })
    );
};

export const getSSRegions = () => (dispatch) => {
  const options = {
    url: "/api/ss/getregions",
    method: "GET",
    withCredentials: true
  };
  axios(options)
    .then((res) => res.data)
    .then((res) =>
      dispatch({
        type: act.GETSSREGIONS,
        payload: res
      })
    );
};

export const getSSFormats = () => (dispatch) => {
  const options = {
    url: "/api/ss/getformats",
    method: "GET",
    withCredentials: true
  };
  axios(options)
    .then((res) => res.data)
    .then((res) =>
      dispatch({
        type: act.GETSSFORMATS,
        payload: res
      })
    );
};

export const getSSOthers = () => (dispatch) => {
  const options = {
    url: "/api/ss/getothers",
    method: "GET",
    withCredentials: true
  };
  axios(options)
    .then((res) => res.data)
    .then((res) =>
      dispatch({
        type: act.GETSSOTHERS,
        payload: res
      })
    );
};

export const setSSLoaded = () => (dispatch) => {
  dispatch({
    type: act.SETSSLOADED
  });
};
