import * as act from '../actions';

const initialState = {
  isLoading: true,
  isLoggedIn: false,
  data: []
};

const user = (state = initialState, action) => {
  let tmp_data;
  switch(action.type) {
    case act.GETUSERDATA:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: (typeof action.payload._id === "string" ? true : false),
        data: action.payload
      };
    case act.ADDTOCART:
      tmp_data = state.data;
      tmp_data.cart.push(action.payload);
      return {
        ...state,
        data: tmp_data
      };
    case act.REMOVEFROMCART:
      tmp_data = state.data;
      const index = tmp_data.cart.indexOf(action.payload);
      tmp_data.cart.splice(index, 1);
      return {
        ...state,
        data: tmp_data
      };
    case act.EMPTYCART:
      tmp_data = state.data;
      tmp_data.cart = [];
      return {
        ...state,
        data: tmp_data
      };
    default:
      return state;
  }
};

export default user;
