import React from 'react';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Button } from 'react-bootstrap';

import * as settings from '../settings/settings';
import loadingGif from '../assets/loading.gif';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {
    if(this.state.isLoading && !this.props.user.isLoading) {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate() {
    if(this.state.isLoading && !this.props.user.isLoading) {
      this.setState({ isLoading: false });
    }
  }

  content = () => {
    const { isLoggedIn } = this.props.user;
    return (
      <React.Fragment>
        { isLoggedIn ? this.userView() : <Navigate to="/login?notallowed=true" /> }
      </React.Fragment>
    );
  }

  userView = () => {
    const { data } = this.props.user;
    return (
      <React.Fragment>
        <h4 className="center">個人資料</h4>
        <table style={{ margin: "0 auto" }}>
          <tbody>
            <tr>
              <th>帳號</th>
              <td>{ data.username }</td>
            </tr>
            <tr>
              <th>ID</th>
              <td>{ data.ident }</td>
            </tr>
            <tr>
              <th>E-Mail</th>
              <td>{ data.email }</td>
            </tr>
            <tr>
              <th>密碼</th>
              <td><Button variant="outline-primary" size="sm" href="/password">更改密碼</Button></td>
            </tr>
            <tr>
              <th>權限</th>
              <td>{ data.admin > 0 ? "管理員" : "客戶" }</td>
            </tr>
            <tr>
              <th>交易</th>
              <td><Button variant="outline-primary" size="sm" href="/orders">交易紀錄</Button></td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    )
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div className="profile">
        <Helmet>
          <title>{ settings.PROJECTNAME } - 個人資料</title>
        </Helmet>
        { isLoading ? <img src={ loadingGif } alt="loading" /> : this.content() }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(Profile);
