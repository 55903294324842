import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import { Button } from 'react-bootstrap';

import { getUserData } from '../actions/userAction';

import * as settings from '../settings/settings';
import loadingGif from '../assets/loading.gif';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {
    this.props.dispatch(getUserData());
  }

  componentDidUpdate() {
    if(this.state.isLoading && !this.props.user.isLoading) {
      this.setState({ isLoading: false });
    }
  }

  logout = () => {
    const options = {
      url: "/api/user/logout",
      method: "GET",
      withCredentials: true
    };
    axios(options).then(() => (window.location = "/"));
  }

  content = () => {
    const { isLoggedIn } = this.props.user;
    return (
      <React.Fragment>
        { isLoggedIn ? this.userView() : this.guestView() }
      </React.Fragment>
    );
  }

  userView = () => {
    const { data } = this.props.user;
    return (
      <React.Fragment>
        <Button variant="outline-dark" href="/">首頁</Button>
        { data.admin > 0 ? <Button variant="outline-success" href="/manage">管理</Button> : null }
        <Button variant="outline-success" href="/cart">購物車 { data.cart.length }</Button>
        <Button variant="outline-secondary" href="/download">試題下載</Button>
        <Button variant="outline-primary" href="/profile">個人資料</Button>
        <Button variant="outline-danger" onClick={ () => this.logout() }>登出</Button>
      </React.Fragment>
    );
  }

  guestView = () => {
    return (
      <React.Fragment>
        <Button variant="outline-dark" href="/">首頁</Button>
        <Button variant="outline-primary" href="/login">登入</Button>
        <Button variant="outline-secondary" href="/register">註冊</Button>
      </React.Fragment>
    );
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div className="header">
        <div className="left inline-block w30 center">
          <h1>{ settings.PROJECTNAME }</h1>
        </div>
        <div className="right inline-block w70 center vtop">
          { isLoading ? <img src={ loadingGif } alt="loading" /> : this.content() }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(Header);
