import axios from 'axios';

import * as act from './index';

export const getUserData = () => (dispatch) => {
  const options = {
    url: "/api/user/getdata",
    method: "GET",
    withCredentials: true
  };
  axios(options)
    .then((res) => res.data)
    .then((res) =>
      dispatch({
        type: act.GETUSERDATA,
        payload: res
      })
    );
};

export const addToCart = (res) => (dispatch) => {
  const options = {
    url: "/api/user/addtocart",
    method: "POST",
    data: {
      item: res
    },
    withCredentials: true
  };
  axios(options)
    .then(() =>
      dispatch({
        type: act.ADDTOCART,
        payload: res
      })
    );
};

export const removeFromCart = (res) => (dispatch) => {
  const options = {
    url: "/api/user/removefromcart",
    method: "POST",
    data: {
      item: res
    },
    withCredentials: true
  };
  axios(options)
    .then(() =>
      dispatch({
        type: act.REMOVEFROMCART,
        payload: res
      })
    );
};

export const emptyCart = (res) => (dispatch) => {
  const options = {
    url: "/api/user/emptycart",
    method: "POST",
    withCredentials: true
  };
  axios(options)
    .then(() =>
      dispatch({
        type: act.EMPTYCART,
        payload: res
      })
    );
};
