import React from 'react';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import axios from 'axios';

import { Button } from 'react-bootstrap';

import { getSSFormats, setSSLoaded } from '../actions/ssAction';

import * as settings from '../settings/settings';
import loadingGif from '../assets/loading.gif';

class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      boughtLoading: true,
      boughtItems: []
    };
  }

  componentDidMount() {
    this.props.dispatch(getSSFormats());
    this.props.dispatch(setSSLoaded());
  }

  componentDidUpdate() {
    if(this.state.isLoading && !this.props.user.isLoading && !this.props.ss.isLoading) {
      this.setState({ isLoading: false });
      this.getItemData();
    }
  }

  content = () => {
    const { isLoggedIn } = this.props.user;
    return (
      <React.Fragment>
        { isLoggedIn ? this.userView() : <Navigate to="/login?notallowed=true" /> }
      </React.Fragment>
    );
  }

  userView = () => {
    return (
      <React.Fragment>
        <h4 className="center">已購試題下載</h4>
        { this.state.boughtLoading ? <p className="center"><img src={ loadingGif } alt="loading" /></p> : this.showBoughtItem() }
      </React.Fragment>
    )
  }

  getItemData = () => {
    const options = {
      url: "/api/file/getfilesdata",
      method: "GET",
      params: {
        files: this.props.user.data.bought
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => this.setState({ boughtItems: res, boughtLoading: false }))
      .catch((err) => console.log(err))
  }

  showBoughtItem = () => {
    if(this.state.boughtItems.length > 0) {
      return (
        <React.Fragment>
          <p className="center">試題數量：{ this.state.boughtItems.length }</p>
          <table style={{ margin: "0 auto" }}>
            <tbody>
              <tr className="center">
                <th>名稱</th>
                <th>格式</th>
                <th>下載</th>
              </tr>
              { this.state.boughtItems.map((item) => (
                <tr>
                  <th>{ item.title }</th>
                  <td className="center">{ this.props.ss.formats.find(({ _id }) => _id === item.format).name }</td>
                  <td className="center"><Button variant="outline-secondary" size="sm" onClick={ () => this.downloadFile(item._id, item.filename, item.title) }>下載</Button></td>
                </tr>
              )) }
            </tbody>
          </table>
        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>
          <p className="center">試題數量：0</p>
          <p className="center">查無試題</p>
        </React.Fragment>
      );
    }
  }

  downloadFile = (id, filename, title) => {
    const arr = filename.split(".");
    const ext = arr[arr.length - 1];
    const newfilename = title + "." + ext;
    const options = {
      url: "/api/file/downloadfile",
      method: "POST",
      data: {
        id: id
      },
      withCredentials: true,
      responseType: "blob"
    };
    axios(options)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", newfilename);
        document.body.appendChild(link);
        link.click();
      })
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div className="download">
        <Helmet>
          <title>{ settings.PROJECTNAME } - 試題下載</title>
        </Helmet>
        { isLoading ? <img src={ loadingGif } alt="loading" /> : this.content() }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    ss: state.ss
  };
}

export default connect(mapStateToProps)(Download);
