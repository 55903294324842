import React from 'react';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import axios from 'axios';

import { Button } from 'react-bootstrap';

import * as settings from '../settings/settings';
import loadingGif from '../assets/loading.gif';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      msg: "",
      username: "",
      ident: "",
      password: "",
      buttonDisabled: false
    };
  }

  componentDidMount() {
    if(this.state.isLoading && !this.props.user.isLoading) {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate() {
    if(this.state.isLoading && !this.props.user.isLoading) {
      this.setState({ isLoading: false });
    }
  }

  login = (e) => {
    e.preventDefault();
    this.setState({ buttonDisabled: true });
    setTimeout(() => this.setState({ buttonDisabled: false }), 3000);
    const options = {
      url: "/api/user/login",
      method: "POST",
      data: {
        username: this.state.username,
        ident: this.state.ident,
        password: this.state.password
      },
      withCredentials: true
    };
    axios(options)
      .then(() => (window.location = "/"))
      .catch((err) => this.setState({ msg: err.response.data, password: "" }));
  }

  content = () => {
    const { isLoggedIn } = this.props.user;
    return (
      <React.Fragment>
        { isLoggedIn ? <Navigate to="/" /> : this.guestView() }
      </React.Fragment>
    );
  }

  guestView = () => {
    const { msg, username, ident, password } = this.state;
    return (
      <React.Fragment>
        <form onSubmit={ (e) => this.login(e) }>
          <table className="no-border" style={{ margin: "0 auto", textAlign: "right" }}>
            <tbody>
              <tr style={{ textAlign: "center" }}>
                <td colSpan="2"><h3>登入</h3></td>
              </tr>
              <tr>
                { msg.length > 0 ? <td colSpan="2">{ msg.length > 0 ? <p className="red">{ msg }</p> : null }</td> : null }
              </tr>
              <tr>
                <td>帳號：</td>
                <td><input type="text" name="username" value={ username } onChange={ (e) => this.handleUsernameChange(e) } required /></td>
              </tr>
              <tr>
                <td>身分證字號：</td>
                <td><input type="text" name="ident" value={ ident } onChange={ (e) => this.setState({ ident: e.target.value }) } minLength={ settings.IDENT_LENGTH } maxLength={ settings.IDENT_LENGTH } required /></td>
              </tr>
              <tr>
                <td>密碼：</td>
                <td><input type="password" name="password" value={ password } onChange={ (e) => this.handlePasswordChange(e) } required /></td>
              </tr>
              <tr style={{ textAlign: "center" }}>
                <td colSpan="2"><Button variant="outline-primary" type="submit" name="submit" disabled={ this.state.buttonDisabled }>{ this.state.buttonDisabled ? "請稍候" : "登入" }</Button></td>
              </tr>
              <tr style={{ textAlign: "center" }}>
                <td colSpan="2">還沒註冊？前往<a href="/register">註冊</a>。</td>
              </tr>
            </tbody>
          </table>
        </form>
      </React.Fragment>
    );
  }

  handleUsernameChange = (e) => {
    const username = e.target.value;
    this.setState({ username });
  }

  handlePasswordChange = (e) => {
    const password = e.target.value;
    this.setState({ password });
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div className="login center">
        <Helmet>
          <title>{ settings.PROJECTNAME } - 登入</title>
        </Helmet>
        { isLoading ? <img src={ loadingGif } alt="loading" /> : this.content() }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(Login);
