import * as act from '../actions';

const initialState = {
  isLoading: true,
  types: [],
  years: [],
  times: [],
  grades: [],
  regions: [],
  formats: [],
  others: []
};

const ss = (state = initialState, action) => {
  switch(action.type) {
    case act.GETSSTYPES:
      return {
        ...state,
        types: action.payload
      };
    case act.GETSSYEARS:
      return {
        ...state,
        years: action.payload
      }
    case act.GETSSTIMES:
      return {
        ...state,
        times: action.payload
      }
    case act.GETSSGRADES:
      return {
        ...state,
        grades: action.payload
      }
    case act.GETSSREGIONS:
      return {
        ...state,
        regions: action.payload
      }
    case act.GETSSFORMATS:
      return {
        ...state,
        formats: action.payload
      }
    case act.GETSSOTHERS:
      return {
        ...state,
        others: action.payload
      }
    case act.SETSSLOADED:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state;
  }
};

export default ss;
