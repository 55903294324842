import React from 'react';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import axios from 'axios';
import InnerHTML from 'dangerously-set-html-content';

import { Button } from 'react-bootstrap';

import { emptyCart } from '../actions/userAction';
import { getSSFormats, setSSLoaded } from '../actions/ssAction';

import * as settings from '../settings/settings';
import loadingGif from '../assets/loading.gif';

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      cartLoading: true,
      cartItems: [],
      buttonDisabled: false,
      payHtm: ""
    };
  }

  componentDidMount() {
    this.props.dispatch(getSSFormats());
    this.props.dispatch(setSSLoaded());
  }

  componentDidUpdate() {
    if(this.state.isLoading && !this.props.user.isLoading && !this.props.ss.isLoading) {
      this.setState({ isLoading: false });
      this.getItemData();
    }
  }

  content = () => {
    const { isLoggedIn } = this.props.user;
    return (
      <React.Fragment>
        { isLoggedIn ? this.userView() : <Navigate to="/login?notallowed=true" /> }
      </React.Fragment>
    );
  }

  userView = () => {
    return (
      <React.Fragment>
        <h4 className="center">購物車商品</h4>
        { this.state.cartLoading ? <p className="center"><img src={ loadingGif } alt="loading" /></p> : this.showCartItem() }
      </React.Fragment>
    );
  }

  getItemData = () => {
    const options = {
      url: "/api/file/getfilesdata",
      method: "GET",
      params: {
        files: this.props.user.data.cart
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => this.setState({ cartItems: res, cartLoading: false }))
      .catch((err) => console.log(err))
  }

  showCartItem = () => {
    if(this.state.cartItems.length > 0) {
      return (
        <React.Fragment>
          <p className="center">商品數量：{ this.state.cartItems.length }</p>
          <div className="cartitem bg-lblue">
            { this.state.cartItems.map((item) => (
              <React.Fragment>
                <p>商品名稱：{ item.title }</p>
                <p>商品編號：{ item._id }</p>
                <p>商品格式：{ this.props.ss.formats.find(({ _id }) => _id === item.format).name }</p>
                <p className="tright green">${ this.props.ss.formats.find(({ _id }) => _id === item.format).price }</p>
                <hr className="m0" />
              </React.Fragment>
            )) }
            <p className="tright">總計：NT${ this.calculateSum() }</p>
            <hr className="m0" />
            <p className="center">
              <Button variant="outline-danger" size="sm" onClick={ () => this.emptyItemCart() }>清空購物車</Button>
              <Button variant="outline-primary" size="sm" onClick={ () => this.pay() } disabled={ this.state.buttonDisabled }>{ this.state.buttonDisabled ? "請稍候" : "前往信用卡付款" }</Button>
              <Button variant="outline-secondary" size="sm" onClick={ () => this.payAtm() } disabled={ this.state.buttonDisabled }>{ this.state.buttonDisabled ? "請稍候" : "前往取得ATM付款資訊" }</Button>
            </p>
          </div>
          <p></p>
          <p className="center red"><b>注意：信用卡付款手續費$5元，ATM付款手續費$10元。</b></p>
          <p className="center red"><b>注意：ATM付款請在當下截圖或列印付款資訊，離開頁面後不再提供。</b></p>
          <div>
            <InnerHTML html={ this.state.payHtm } />
          </div>
        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>
          <p className="center">商品數量：0</p>
          <div className="cartitem bg-lblue">
            <p className="center">查無商品</p>
          </div>
        </React.Fragment>
      );
    }
  }

  emptyItemCart = () => {
    this.props.dispatch(emptyCart());
    this.setState({ cartItems: [] });
  }

  pay = () => {
    this.setState({ buttonDisabled: true });
    setTimeout(() => this.setState({ buttonDisabled: false }), 5000);
    const options = {
      url: "/api/trade/pay",
      method: "POST",
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => this.setState({ payHtm: res }))
      .catch((err) => console.log(err));
  }

  payAtm = () => {
    this.setState({ buttonDisabled: true });
    setTimeout(() => this.setState({ buttonDisabled: false }), 5000);
    const options = {
      url: "/api/trade/pay_atm",
      method: "POST",
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => this.setState({ payHtm: res }))
      .catch((err) => console.log(err));
  }

  calculateSum = () => {
    let totalSum = 0;
    this.state.cartItems.map((item) => (
      totalSum += this.props.ss.formats.find(({ _id }) => _id === item.format).price
    ));
    return (
      <React.Fragment>{ totalSum }</React.Fragment>
    );
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div className="cart">
        <Helmet>
          <title>{ settings.PROJECTNAME } - 購物車</title>
        </Helmet>
        { isLoading ? <img src={ loadingGif } alt="loading" /> : this.content() }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    ss: state.ss
  };
}

export default connect(mapStateToProps)(Cart);
