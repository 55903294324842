import React from 'react';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import axios from 'axios';

import { Button } from 'react-bootstrap';

import * as settings from '../settings/settings';
import loadingGif from '../assets/loading.gif';

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      checked: false,
      confirmedCheck: false,
      msg: "",
      username: "",
      ident: "",
      email: "",
      password: "",
      passConf: "",
      buttonDisabled: false
    };
  }

  componentDidMount() {
    if(this.state.isLoading && !this.props.user.isLoading) {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate() {
    if(this.state.isLoading && !this.props.user.isLoading) {
      this.setState({ isLoading: false });
    }
  }

  register = (e) => {
    e.preventDefault();
    this.setState({ buttonDisabled: true });
    setTimeout(() => this.setState({ buttonDisabled: false }), 3000);
    if(this.state.username.length < settings.MIN_USERNAME_LENGTH) {
      this.setState({
        msg: "帳號長度需大於或等於" + settings.MIN_USERNAME_LENGTH + "。",
        username: "",
        password: "",
        passConf: ""
      });
      return;
    }
    let regEx = /^[0-9a-zA-Z]+$/;
    if(!this.state.username.match(regEx)) {
      this.setState({
        msg: "帳號只能使用阿拉伯數字及英文字母。",
        username: "",
        password: "",
        passConf: ""
      });
      return;
    }
    regEx = /^[a-zA-Z][12]\d{8}$/;
    if((this.state.ident.length !== settings.IDENT_LENGTH) || !this.state.ident.match(regEx)) {
      this.setState({
        msg: "身分證字號不正確。",
        ident: ""
      });
      return;
    }
    regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if((this.state.email.length < settings.MIN_EMAIL_LENGTH) || !this.state.email.match(regEx)) {
      this.setState({
        msg: "E-Mail不正確。",
        email: ""
      });
      return;
    }
    if(this.state.password.length < settings.MIN_PASSWORD_LENGTH) {
      this.setState({
        msg: "密碼長度需大於或等於" + settings.MIN_PASSWORD_LENGTH + "。",
        password: "",
        passConf: ""
      });
      return;
    }
    if(this.state.password !== this.state.passConf) {
      this.setState({
        msg: "密碼不一致。",
        password: "",
        passConf: ""
      });
      return;
    }
    const options = {
      url: "/api/user/register",
      method: "POST",
      data: {
        username: this.state.username,
        ident: this.state.ident.toUpperCase(),
        email: this.state.email.toLowerCase(),
        password: this.state.password
      },
      withCredentials: true
    };
    axios(options)
      .then(() => (window.location = "/login?reg=success"))
      .catch((err) => this.setState({ msg: err.response.data, password: "", passConf: "" }));
  }

  content = () => {
    const { confirmedCheck } = this.state;
    const { isLoggedIn } = this.props.user;
    return (
      <React.Fragment>
        { isLoggedIn ? <Navigate to="/" /> : (confirmedCheck ? this.guestView() : this.checkView()) }
      </React.Fragment>
    );
  }

  checkView = () => {
    const { checked } = this.state;
    return (
      <React.Fragment>
        <form>
          <table className="no-border" style={{ margin: "0 auto", textAlign: "left" }}>
            <tbody>
              <tr style={{ textAlign: "center" }}>
                <td><h3>會員使用條款</h3></td>
              </tr>
              <tr>
                <td><h6>一、認知與接受條款</h6></td>
              </tr>
              <tr>
                <td>
                  <ol>
                    <li>當會員完成{ settings.PROJECTNAME }(以下簡稱「本網站」)之會員註冊手續、或開始使用本服務時，即表示已閱讀、瞭解並同意接受本服務條款之所有內容，並完全接受本服務現有與未來衍生的服務項目及內容。本網站管理團隊(以下簡稱「本團隊」)有權於任何時間修改或變更本服務條款之內容，修改後的服務條款內容將公佈網站上，團隊將不會個別通知會員，建議會員隨時注意該等修改或變更。會員於任何修改或變更後繼續使用本服務時，視為會員已閱讀、瞭解並同意接受該等修改或變更。若不同意上述的服務條款修訂或更新方式，或不接受本服務條款的其他任一約定，會員應立即停止使用本服務。</li>
                    <li>為了確保會員之個人資料、隱私及消費者權益之保護，於交易過程中將使用會員之個人資料，謹依個人資料保護法第8條規定告知以下事項：
                      <ol>
                        <li>蒐集之目的：蒐集之目的在於客戶管理與服務。本團隊將藉由加入會員之過程或進行交易之過程來蒐集個人資料。</li>
                        <li>蒐集之個人資料類別：本團隊於網站內蒐集的個人資料包括，會員之身分證字號、電子郵件(辨識個人者)、信用卡或金融機構帳戶資訊(辨識財務者)。</li>
                        <li>利用期間、地區、對象及方式：
                          <ul>
                            <li>期間：會員當事人要求停止使用或本團隊停止提供服務之日為止。</li>
                            <li>地區：會員之個人資料將使用於台灣地區。</li>
                          </ul>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td><h6>二、會員的註冊義務</h6></td>
              </tr>
              <tr>
                <td>為了能使用本服務，會員同意以下事項：
                  <ol>
                    <li>依本服務註冊表之提示提供會員本人正確、最新的資料，且不得以第三人之名義註冊為會員。每位會員僅能註冊登錄一個帳號，不可重覆註冊登錄。</li>
                    <li>若會員提供任何錯誤或不實的資料、或未按指示提供資料、或欠缺必要之資料、或有重覆註冊帳號等情事時，本團隊有權不經事先通知，逕行暫停或終止會員的帳號，並拒絕會員使用本服務之全部或一部。</li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td><h6>三、會員帳號、密碼及安全</h6></td>
              </tr>
              <tr>
                <td>
                  <ol>
                    <li>完成本服務的登記程序之後，會員有責任維持自行設定密碼及帳號之機密安全。任何依照規定方法輸入會員帳號及密碼與登入資料一致時，無論是否由本人親自輸入，均將推定為會員本人所使用，利用該密碼及帳號所進行的一切行動，會員本人應負完全責任。</li>
                    <li>會員同意以下事項：
                      <ol>
                        <li>會員的密碼或帳號遭到盜用或有其他任何安全問題發生時，會員將立即通知本團隊。</li>
                        <li>每次連線完畢，均結束會員的帳號使用。</li>
                        <li>會員的帳號、密碼及會員權益均僅供會員個人使用及享有，不得轉借、轉讓他人或與他人合用。</li>
                        <li>帳號及密碼遭盜用、不當使用或其他本團隊無法辯識是否為本人親自使用之情況時，對此所致之損害，除證明係因可歸責於本團隊之事由所致，本團隊將不負任何責任。</li>
                        <li>本團隊若知悉會員之帳號密碼確係遭他人冒用時，將立即暫停該帳號之使用(含該帳號所生交易之處理)。</li>
                      </ol>
                    </li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td><h6>四、使用者的守法義務及承諾</h6></td>
              </tr>
              <tr>
                <td>會員承諾絕不為任何非法目的或以任何非法方式使用本服務，並承諾遵守中華民國相關法規及一切使用網際網路之國際慣例。會員若係中華民國以外之使用者，並同意遵守所屬國家或地域之法令。會員同意並保證不得利用本服務從事侵害他人權益或違法之行為，包括但不限於：
                  <ol>
                    <li>侵害或毀損本團隊或他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利。</li>
                    <li>違反依法律或契約所應負之保密義務。</li>
                    <li>冒用他人名義使用本服務。</li>
                    <li>從事未經本團隊事前授權的商業行為。</li>
                    <li>對本服務其他用戶或第三人產生困擾、不悅或違反一般網路禮節致生反感之行為。</li>
                    <li>其他不符本服務所提供的使用目的之行為或本團隊有正當理由認為不適當之行為。</li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td><h6>五、服務之停止、中斷</h6></td>
              </tr>
              <tr>
                <td>本團隊將依一般合理之技術及方式，維持系統及服務之正常運作。但於以下各項情況時，本團隊有權可以停止、中斷提供本服務：
                  <ol>
                    <li>本團隊網站電子通信設備進行必要之保養及施工時。</li>
                    <li>發生突發性之電子通信設備故障時。</li>
                    <li>本團隊網站申請之電子通信服務被停止，無法提供服務時。</li>
                    <li>由於天災等不可抗力之因素或其他不可歸責於本團隊致使本團隊網站無法提供服務時。</li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td><h6>六、交易行為</h6></td>
              </tr>
              <tr>
                <ul>
                  <li>為預防惡意退款之行為，會員同意使用本服務訂購產品後，恕無法進行退款，購買前請務必詳閱服務條款。</li>
                </ul>
              </tr>
              <tr>
                <td><h6>七、拒絕或中止會員的使用</h6></td>
              </tr>
              <tr>
                <ul>
                  <li>會員同意本團隊得基於維護交易安全之考量，因任何理由，包含但不限於缺乏使用，或違反本服務條款的明文規定及精神，終止會員的密碼、帳號（或其任何部分）或本服務（或其任何部分）之使用，或將本服務內任何「會員內容」加以移除並刪除。此外，會員同意若本服務（或其任何部分）之使用被終止，本團隊對會員或任何第三人均不承擔責任。</li>
                </ul>
              </tr>
              <tr style={{ textAlign: "center" }}>
                <td><input type="checkbox" checked={ checked } onChange={ () => this.setState({ checked: !this.state.checked }) } /> 我已詳閱並同意以上條款之內容</td>
              </tr>
              <tr style={{ textAlign: "center" }}>
                <td><Button variant="outline-secondary" size="sm" onClick={ () => this.gotoRegister() }>前往註冊</Button></td>
              </tr>
            </tbody>
          </table>
        </form>
      </React.Fragment>
    );
  }

  gotoRegister = () => {
    const { checked } = this.state;
    if(!checked) {
      alert("請詳閱並同意條款之內容！");
      return;
    }
    else {
      this.setState({ confirmedCheck: true });
    }
  }

  guestView = () => {
    const { msg, username, ident, email, password, passConf } = this.state;
    return (
      <React.Fragment>
        <form onSubmit={ (e) => this.register(e) }>
          <table className="no-border" style={{ margin: "0 auto", textAlign: "right" }}>
            <tbody>
              <tr style={{ textAlign: "center" }}>
                <td colSpan="2"><h3>註冊</h3></td>
              </tr>
              <tr>
                { msg.length > 0 ? <td colSpan="2">{ msg.length > 0 ? <p className="center red">{ msg }</p> : null }</td> : null }
              </tr>
              <tr>
                <td>帳號：</td>
                <td><input type="text" name="username" value={ username } onChange={ (e) => this.handleUsernameChange(e) } minLength={ settings.MIN_USERNAME_LENGTH } required /></td>
              </tr>
              <tr>
                <td>身分證字號：</td>
                <td><input type="text" name="ident" value={ ident } onChange={ (e) => this.setState({ ident: e.target.value }) } minLength={ settings.IDENT_LENGTH } maxLength={ settings.IDENT_LENGTH } required /></td>
              </tr>
              <tr>
                <td>E-Mail：</td>
                <td><input type="email" name="email" value={ email } onChange={ (e) => this.setState({ email: e.target.value }) } minLength={ settings.MIN_EMAIL_LENGTH } required /></td>
              </tr>
              <tr>
                <td>密碼：</td>
                <td><input type="password" name="password" value={ password } onChange={ (e) => this.handlePasswordChange(e) } minLength={ settings.MIN_PASSWORD_LENGTH } required /></td>
              </tr>
              <tr>
                <td>密碼確認：</td>
                <td><input type="password" name="passConf" value={ passConf } onChange={ (e) => this.handlePassConfChange(e) } minLength={ settings.MIN_PASSWORD_LENGTH } required /></td>
              </tr>
              <tr style={{ textAlign: "center" }}>
                <td colSpan="2"><Button variant="outline-secondary" type="submit" name="submit" disabled={ this.state.buttonDisabled }>{ this.state.buttonDisabled ? "請稍候" : "註冊" }</Button></td>
              </tr>
              <tr style={{ textAlign: "center" }}>
                <td colSpan="2">已有帳號？前往<a href="/login">登入</a>。</td>
              </tr>
            </tbody>
          </table>
        </form>
      </React.Fragment>
    );
  }

  handleUsernameChange = (e) => {
    const username = e.target.value;
    this.setState({ username });
  }

  handlePasswordChange = (e) => {
    const password = e.target.value;
    this.setState({ password });
  }

  handlePassConfChange = (e) => {
    const passConf = e.target.value;
    this.setState({ passConf });
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div className="register center">
        <Helmet>
          <title>{ settings.PROJECTNAME } - 註冊</title>
        </Helmet>
        { isLoading ? <img src={ loadingGif } alt="loading" /> : this.content() }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(Register);
