import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import Manage from './components/Manage';
import Cart from './components/Cart';
import CartSuccess from './components/CartSuccess';
import CartFail from './components/CartFail';
import Download from './components/Download';
import Profile from './components/Profile';
import Password from './components/Password';
import Orders from './components/Orders';
import OrdersSuccess from './components/OrdersSuccess';
import Error from './components/Error';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <hr />
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/login" element={ <Login /> } />
        <Route path="/register" element={ <Register /> } />
        <Route path="/manage" element={ <Manage /> } />
        <Route path="/cart" element={ <Cart /> } />
        <Route path="/cart/success" element={ <CartSuccess /> } />
        <Route path="/cart/fail" element={ <CartFail /> } />
        <Route path="/download" element={ <Download /> } />
        <Route path="/profile" element={ <Profile /> } />
        <Route path="/password" element={ <Password /> } />
        <Route path="/orders" element={ <Orders /> } />
        <Route path="/orders/success" element={ <OrdersSuccess /> } />
        <Route path="/error" element={ <Error /> } />
        <Route path="*" element={ <Navigate to="/error" /> } />
      </Routes>
      <hr />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
