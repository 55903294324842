import React from 'react';
import { Helmet } from 'react-helmet';

import { Button } from 'react-bootstrap';

import * as settings from '../settings/settings';
import checkPng from '../assets/check.png';

class OrdersSuccess extends React.Component {
  render() {
    return (
      <div className="orderssuccess center">
        <Helmet>
          <title>{ settings.PROJECTNAME } - 訂單建立成功</title>
        </Helmet>
        <img src={ checkPng } width="100" alt="success" />
        <h3 className="green">訂單建立成功</h3>
        <p style={{ marginTop: 30 }}>您的訂單已建立，請在3日內付款至指定帳號，系統將於付款後30分鐘至1小時內更新付款狀態。</p>
        <p style={{ marginTop: 30 }}>若對此交易有任何問題，您隨時可以到<b>“交易紀錄”</b>查詢過往交易。</p>
        <Button variant="outline-secondary" size="sm" href="/orders">前往交易紀錄</Button>
      </div>
    );
  }
}

export default OrdersSuccess;
