import React from 'react';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import axios from 'axios';

import { Button } from 'react-bootstrap';

import * as settings from '../settings/settings';
import loadingGif from '../assets/loading.gif';

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      ordersLoading: true,
      orders: [],
      buttonDisabled: false,
      msg: ""
    };
  }

  componentDidMount() {
    if(this.state.isLoading && !this.props.user.isLoading) {
      this.setState({ isLoading: false });
      this.getOrders();
    }
  }

  componentDidUpdate() {
    if(this.state.isLoading && !this.props.user.isLoading) {
      this.setState({ isLoading: false });
      this.getOrders();
    }
  }

  content = () => {
    const { isLoggedIn } = this.props.user;
    return (
      <React.Fragment>
        { isLoggedIn ? this.userView() : <Navigate to="/login?notallowed=true" /> }
      </React.Fragment>
    );
  }

  userView = () => {
    return (
      <React.Fragment>
        <h4 className="center">交易紀錄</h4>
        { this.state.ordersLoading ? <p className="center"><img src={ loadingGif } alt="loading" /></p> : this.showOrders() }
      </React.Fragment>
    )
  }

  getOrders = () => {
    const options = {
      url: "/api/trade/getorders",
      method: "GET",
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => this.setState({ orders: res, ordersLoading: false }))
      .catch((err) => console.log(err))
  }

  showOrders = () => {
    const { msg } = this.state;
    if(this.state.orders.length > 0) {
      return (
        <React.Fragment>
          <p className="center">交易數量：{ this.state.orders.length }</p>
          { msg.length > 0 ? <p className="center red">{ msg }</p> : null }
          <table style={{ margin: "0 auto" }}>
            <tbody>
              <tr className="center">
                <th>訂單編號</th>
                <th>建立時間</th>
                <th>交易金額</th>
                <th>商品名稱</th>
                <th>付款狀態</th>
                <th>付款方式</th>
                <th>付款時間</th>
                <th>錯誤訊息</th>
                <th>更新</th>
              </tr>
              { this.state.orders.map((order) => (
                <tr>
                  <td>{ order.MerchantTradeNo }</td>
                  <td>{ order.MerchantTradeDate }</td>
                  <td><span className="green">NT${ order.TotalAmount }</span></td>
                  <td>{ order.ItemName }</td>
                  <td>{ order.Paid ? <span className="green">已付款</span> : <span className="red">未付款</span> }</td>
                  <td>{ order.PaymentType === "Credit_CreditCard" ? "信用卡" : order.PaymentType }</td>
                  <td>{ order.PaymentDate }</td>
                  <td>{ order.Error }</td>
                  <td>{ order.Paid ? null : <Button variant="outline-primary" size="sm" onClick={ () => this.getTradeInfo(order.MerchantTradeNo) } disabled={ this.state.buttonDisabled }>{ this.state.buttonDisabled ? "請稍候" : "更新" }</Button> }</td>
                </tr>
              )) }
            </tbody>
          </table>
        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>
          <p className="center">交易數量：0</p>
          <p className="center">查無交易</p>
        </React.Fragment>
      );
    }
  }

  getTradeInfo = (no) => {
    this.setState({ buttonDisabled: true });
    setTimeout(() => this.setState({ buttonDisabled: false }), 3000);
    const options = {
      url: "/api/trade/tradeinfo",
      method: "POST",
      data: {
        MerchantTradeNo: no
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.getOrders()
        this.setState({ msg: res })
      })
      .catch((err) => this.setState({ msg: err.response.data }));
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div className="orders">
        <Helmet>
          <title>{ settings.PROJECTNAME } - 交易紀錄</title>
        </Helmet>
        { isLoading ? <img src={ loadingGif } alt="loading" /> : this.content() }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(Orders);
