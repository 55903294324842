import React from 'react';
import { Helmet } from 'react-helmet';

import { Button } from 'react-bootstrap';

import * as settings from '../settings/settings';
import checkPng from '../assets/check.png';

class CartSuccess extends React.Component {
  render() {
    return (
      <div className="cartsuccess center">
        <Helmet>
          <title>{ settings.PROJECTNAME } - 付款成功</title>
        </Helmet>
        <img src={ checkPng } width="100" alt="success" />
        <h3 className="green">付款成功</h3>
        <p style={{ marginTop: 30 }}>感謝您的購買，您隨時都可以前往<b>“試題下載”</b>下載您購買過的試題。</p>
        <Button variant="outline-secondary" size="sm" href="/download">前往試題下載</Button>
        <p style={{ marginTop: 30 }}>若對此交易有任何問題，您隨時可以到<b>“交易紀錄”</b>查詢過往交易。</p>
        <Button variant="outline-secondary" size="sm" href="/orders">前往交易紀錄</Button>
      </div>
    );
  }
}

export default CartSuccess;
