import React from 'react';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import axios from 'axios';

import { Button } from 'react-bootstrap';

import * as settings from '../settings/settings';
import loadingGif from '../assets/loading.gif';

class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      msg: "",
      oldPass: "",
      password: "",
      passConf: "",
      buttonDisabled: false
    };
  }

  componentDidMount() {
    if(this.state.isLoading && !this.props.user.isLoading) {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate() {
    if(this.state.isLoading && !this.props.user.isLoading) {
      this.setState({ isLoading: false });
    }
  }

  changePass = (e) => {
    e.preventDefault();
    this.setState({ buttonDisabled: true });
    setTimeout(() => this.setState({ buttonDisabled: false }), 3000);
    if(this.state.oldPass.length === 0) {
      this.setState({
        msg: "請輸入舊密碼。"
      });
      return;
    }
    if(this.state.password.length < settings.MIN_PASSWORD_LENGTH) {
      this.setState({
        msg: "密碼長度需大於或等於" + settings.MIN_PASSWORD_LENGTH + "。",
        password: "",
        passConf: ""
      });
      return;
    }
    if(this.state.password !== this.state.passConf) {
      this.setState({
        msg: "密碼不一致。",
        password: "",
        passConf: ""
      });
      return;
    }
    if(this.state.oldPass === this.state.password) {
      this.setState({
        msg: "新密碼不可與舊密碼相同。",
        password: "",
        passConf: ""
      });
      return;
    }
    const options = {
      url: "/api/user/password",
      method: "POST",
      data: {
        oldPass: this.state.oldPass,
        password: this.state.password
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => this.setState({ msg: res.data, oldPass: "", password: "", passConf: "" }))
      .catch((err) => this.setState({ msg: err.response.data, oldPass: "", password: "", passConf: "" }));
  }

  content = () => {
    const { isLoggedIn } = this.props.user;
    return (
      <React.Fragment>
        { isLoggedIn ? this.userView() : <Navigate to="/login?notallowed=true" /> }
      </React.Fragment>
    );
  }

  userView = () => {
    const { msg, oldPass, password, passConf } = this.state;
    return (
      <React.Fragment>
        <form onSubmit={ (e) => this.changePass(e) }>
          <table className="no-border" style={{ margin: "0 auto", textAlign: "right" }}>
            <tbody>
              <tr style={{ textAlign: "center" }}>
                <td colSpan="2"><h4>更改密碼</h4></td>
              </tr>
              <tr>
                { msg.length > 0 ? <td colSpan="2">{ msg.length > 0 ? <p className="center red">{ msg }</p> : null }</td> : null }
              </tr>
              <tr>
                <td>舊密碼：</td>
                <td><input type="password" name="oldPass" value={ oldPass } onChange={ (e) => this.handleOldPassChange(e) } required /></td>
              </tr>
              <tr>
                <td>新密碼：</td>
                <td><input type="password" name="password" value={ password } onChange={ (e) => this.handlePasswordChange(e) } minLength={ settings.MIN_PASSWORD_LENGTH } required /></td>
              </tr>
              <tr>
                <td>新密碼確認：</td>
                <td><input type="password" name="passConf" value={ passConf } onChange={ (e) => this.handlePassConfChange(e) } minLength={ settings.MIN_PASSWORD_LENGTH } required /></td>
              </tr>
              <tr style={{ textAlign: "center" }}>
                <td colSpan="2"><Button variant="outline-primary" type="submit" name="submit" disabled={ this.state.buttonDisabled }>{ this.state.buttonDisabled ? "請稍候" : "變更" }</Button></td>
              </tr>
            </tbody>
          </table>
        </form>
      </React.Fragment>
    )
  }

  handleOldPassChange = (e) => {
    const oldPass = e.target.value;
    this.setState({ oldPass });
  }

  handlePasswordChange = (e) => {
    const password = e.target.value;
    this.setState({ password });
  }

  handlePassConfChange = (e) => {
    const passConf = e.target.value;
    this.setState({ passConf });
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div className="password">
        <Helmet>
          <title>{ settings.PROJECTNAME } - 更改密碼</title>
        </Helmet>
        { isLoading ? <img src={ loadingGif } alt="loading" /> : this.content() }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(Password);
