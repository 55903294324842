import axios from 'axios';

import * as act from './index';

export const getSiteNotes = () => (dispatch) => {
  const options = {
    url: "/api/site/getnotes",
    method: "GET",
    withCredentials: true
  };
  axios(options)
    .then((res) => res.data)
    .then((res) =>
      dispatch({
        type: act.GETSITENOTES,
        payload: res
      })
    );
};
