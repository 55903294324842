import React from 'react';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import axios from 'axios';

import { Button } from 'react-bootstrap';

import { getSiteNotes } from '../actions/siteAction';
import { getSSTypes, getSSYears, getSSTimes, getSSGrades, getSSRegions, getSSFormats, getSSOthers, setSSLoaded } from '../actions/ssAction';

import * as settings from '../settings/settings';
import loadingGif from '../assets/loading.gif';

class Manage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      manageState: 0,
      activeState: [false, false, false, false, false, false, false, false, false, false, false, false],
      msg: "",
      tmpNotes: "",
      changeNotes: false,
      selectedFile: null,
      fileTitle: "",
      type: "",
      ntype: "",
      etype: "",
      etypen: "",
      dtype: "",
      year: "",
      nyear: "",
      eyear: "",
      eyearn: "",
      dyear: "",
      time: "",
      ntime: "",
      etime: "",
      etimen: "",
      dtime: "",
      grade: "",
      ngrade: "",
      egrade: "",
      egraden: "",
      dgrade: "",
      region: "",
      nregion: "",
      eregion: "",
      eregionn: "",
      dregion: "",
      format: "",
      nformat: "",
      nformatp: 0,
      eformat: "",
      eformatn: "",
      eformatp: 0,
      dformat: "",
      other: "",
      nother: "",
      eother: "",
      eothern: "",
      dother: "",
      filesLoading: true,
      files: [],
      ordersLoading: true,
      orders: [],
      usersLoading: true,
      users: [],
      editFile: "",
      editFileSelected: null,
      editFileTitle: ""
    };
  }

  componentDidMount() {
    this.props.dispatch(getSiteNotes());
    this.props.dispatch(getSSTypes());
    this.props.dispatch(getSSYears());
    this.props.dispatch(getSSTimes());
    this.props.dispatch(getSSGrades());
    this.props.dispatch(getSSRegions());
    this.props.dispatch(getSSFormats());
    this.props.dispatch(getSSOthers());
    this.props.dispatch(setSSLoaded());
  }

  componentDidUpdate() {
    if(this.state.isLoading && !this.props.user.isLoading && !this.props.site.isLoading && !this.props.ss.isLoading) {
      this.setState({ isLoading: false, tmpNotes: this.props.site.notes });
      this.getAllFiles();
      this.getAllOrders();
      this.getAllUsers();
    }
    if(this.state.filesLoading) {
      this.getAllFiles();
    }
  }

  showTypes = () => {
    const { types } = this.props.ss;
    if(typeof types === "string") {
      return (
        <React.Fragment>
          <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button>
        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          { types.map(type => (
            <React.Fragment>
              <input type="radio" className="btn-check" name="types" value={ type._id } id={ type._id } checked={ this.state.type === type._id } onChange={ (e) => this.setState({ type: e.target.value }) } autoComplete="off" />
              <label className="btn btn-outline-secondary btn-sm" htmlFor={ type._id }>{ type.name }</label>
            </React.Fragment>
          )) }
        </React.Fragment>
      )
    }
  }

  showYears = () => {
    const { years } = this.props.ss;
    if(typeof years === "string") {
      return (
        <React.Fragment>
          <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button>
        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          { years.map(year => (
            <React.Fragment>
              <input type="radio" className="btn-check" name="years" value={ year._id } id={ year._id } checked={ this.state.year === year._id } onChange={ (e) => this.setState({ year: e.target.value }) } autoComplete="off" />
              <label className="btn btn-outline-secondary btn-sm" htmlFor={ year._id }>{ year.name }</label>
            </React.Fragment>
          )) }
        </React.Fragment>
      )
    }
  }

  showTimes = () => {
    const { times } = this.props.ss;
    if(typeof times === "string") {
      return (
        <React.Fragment>
          <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button>
        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          { times.map(time => (
            <React.Fragment>
              <input type="radio" className="btn-check" name="times" value={ time._id } id={ time._id } checked={ this.state.time === time._id } onChange={ (e) => this.setState({ time: e.target.value }) } autoComplete="off" />
              <label className="btn btn-outline-secondary btn-sm" htmlFor={ time._id }>{ time.name }</label>
            </React.Fragment>
        )) }
        </React.Fragment>
      )
    }
  }

  showGrades = () => {
    const { grades } = this.props.ss;
    if(typeof grades === "string") {
      return (
        <React.Fragment>
          <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button>
        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          { grades.map(grade => (
            <React.Fragment>
              <input type="radio" className="btn-check" name="grades" value={ grade._id } id={ grade._id } checked={ this.state.grade === grade._id } onChange={ (e) => this.setState({ grade: e.target.value }) } autoComplete="off" />
              <label className="btn btn-outline-secondary btn-sm" htmlFor={ grade._id }>{ grade.name }</label>
            </React.Fragment>
        )) }
        </React.Fragment>
      )
    }
  }

  showRegions = () => {
    const { regions } = this.props.ss;
    if(typeof regions === "string") {
      return (
        <React.Fragment>
          <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button>
        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          { regions.map(region => (
            <React.Fragment>
              <input type="radio" className="btn-check" name="regions" value={ region._id } id={ region._id } checked={ this.state.region === region._id } onChange={ (e) => this.setState({ region: e.target.value }) } autoComplete="off" />
              <label className="btn btn-outline-secondary btn-sm" htmlFor={ region._id }>{ region.name }</label>
            </React.Fragment>
        )) }
        </React.Fragment>
      )
    }
  }

  showFormats = () => {
    const { formats } = this.props.ss;
    if(typeof formats === "string") {
      return (
        <React.Fragment>
          <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button>
        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          { formats.map(format => (
            <React.Fragment>
              <input type="radio" className="btn-check" name="formats" value={ format._id } id={ format._id } checked={ this.state.format === format._id } onChange={ (e) => this.setState({ format: e.target.value }) } autoComplete="off" />
              <label className="btn btn-outline-secondary btn-sm" htmlFor={ format._id }>{ format.name }</label>
            </React.Fragment>
        )) }
        </React.Fragment>
      )
    }
  }

  showOthers = () => {
    const { others } = this.props.ss;
    if(typeof others === "string") {
      return (
        <React.Fragment>
          <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button>
        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          { others.map(other => (
            <React.Fragment>
              <input type="radio" className="btn-check" name="others" value={ other._id } id={ other._id } checked={ this.state.other === other._id } onChange={ (e) => this.setState({ other: e.target.value }) } autoComplete="off" />
              <label className="btn btn-outline-secondary btn-sm" htmlFor={ other._id }>{ other.name }</label>
            </React.Fragment>
        )) }
        </React.Fragment>
      )
    }
  }

  getAllFiles = () => {
    const options = {
      url: "/api/file/getallfiles",
      method: "GET",
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => this.setState({ files: res, filesLoading: false }))
      .catch((err) => console.log(err))
  }

  showFiles = () => {
    if(this.state.files.length > 0) {
      return (
        <React.Fragment>
          <p className="center">檔案數量：{ this.state.files.length }</p>
          <table style={{ margin: "0 auto" }}>
            <tbody>
              <tr className="center">
                <th>標題</th>
                <th>類型</th>
                <th>年度</th>
                <th>時段</th>
                <th>年級</th>
                <th>地區</th>
                <th>格式</th>
                <th>其他</th>
                <th>修改</th>
              </tr>
              { this.state.files.map((file) => (
                <tr>
                  <td>{ file.title }</td>
                  <td>{ this.props.ss.types.find(({ _id }) => _id === file.type) ? this.props.ss.types.find(({ _id }) => _id === file.type).name : "無法讀取" }</td>
                  <td>{ file.year === "N" ? "無" : (this.props.ss.years.find(({ _id }) => _id === file.year) ? this.props.ss.years.find(({ _id }) => _id === file.year).name : "無法讀取") }</td>
                  <td>{ file.time === "N" ? "無" : (this.props.ss.times.find(({ _id }) => _id === file.time) ? this.props.ss.times.find(({ _id }) => _id === file.time).name : "無法讀取") }</td>
                  <td>{ file.grade === "N" ? "無" : (this.props.ss.grades.find(({ _id }) => _id === file.grade) ? this.props.ss.grades.find(({ _id }) => _id === file.grade).name : "無法讀取") }</td>
                  <td>{ file.region === "N" ? "無" : (this.props.ss.regions.find(({ _id }) => _id === file.region) ? this.props.ss.regions.find(({ _id }) => _id === file.region).name : "無法讀取") }</td>
                  <td>{ this.props.ss.formats.find(({ _id }) => _id === file.format) ? this.props.ss.formats.find(({ _id }) => _id === file.format).name : "無法讀取" }</td>
                  <td>{ file.other === "N" ? "無" : (this.props.ss.others.find(({ _id }) => _id === file.other) ? this.props.ss.others.find(({ _id }) => _id === file.other).name : "無法讀取") }</td>
                  <td><Button variant="outline-primary" size="sm" onClick={ () => this.setState({ manageState: 13, activeState: [false, false, false, false, false, false, false, false, false, false, false, false], msg: "", editFile: file._id, editFileSelected: null, editFileTitle: file.title }) }>修改</Button></td>
                </tr>
              )) }
            </tbody>
          </table>
        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>
          <p className="center">檔案數量：0</p>
          <p className="center">查無檔案</p>
        </React.Fragment>
      );
    }
  }

  getAllOrders = () => {
    const options = {
      url: "/api/trade/getallorders",
      method: "GET",
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => this.setState({ orders: res, ordersLoading: false }))
      .catch((err) => console.log(err))
  }

  showOrders = () => {
    if(this.state.orders.length > 0) {
      return (
        <React.Fragment>
          <p className="center">交易數量：{ this.state.orders.length }</p>
          <table style={{ margin: "0 auto" }}>
            <tbody>
              <tr className="center">
                <th>訂單編號</th>
                <th>建立時間</th>
                <th>交易金額</th>
                <th>商品名稱</th>
                <th>付款狀態</th>
                <th>付款方式</th>
                <th>付款時間</th>
                <th>錯誤訊息</th>
              </tr>
              { this.state.orders.map((order) => (
                <tr>
                  <td>{ order.MerchantTradeNo }</td>
                  <td>{ order.MerchantTradeDate }</td>
                  <td><span className="green">NT${ order.TotalAmount }</span></td>
                  <td>{ order.ItemName }</td>
                  <td>{ order.Paid ? <span className="green">已付款</span> : <span className="red">未付款</span> }</td>
                  <td>{ order.PaymentType === "Credit_CreditCard" ? "信用卡" : order.PaymentType }</td>
                  <td>{ order.PaymentDate }</td>
                  <td>{ order.Error }</td>
                </tr>
              )) }
            </tbody>
          </table>
        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>
          <p className="center">交易數量：0</p>
          <p className="center">查無交易</p>
        </React.Fragment>
      );
    }
  }

  getAllUsers = () => {
    const options = {
      url: "/api/user/getallusers",
      method: "GET",
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => this.setState({ users: res, usersLoading: false }))
      .catch((err) => console.log(err))
  }

  showUsers = () => {
    if(this.state.users.length > 0) {
      return (
        <React.Fragment>
          <p className="center">使用者數量：{ this.state.users.length }</p>
          <table style={{ margin: "0 auto" }}>
            <tbody>
              <tr className="center">
                <th>帳號</th>
                <th>ID</th>
                <th>E-Mail</th>
                <th>權限</th>
                <th>購買商品</th>
                <th>註冊日期</th>
                <th>重設密碼（身分證末九碼）</th>
              </tr>
              { this.state.users.map((user) => (
                <tr>
                  <td>{ user.username }</td>
                  <td>{ user.ident }</td>
                  <td>{ user.email }</td>
                  <td>{ user.admin > 0 ? "管理員" : "客戶" }</td>
                  <td>{ user.bought.length }</td>
                  <td>{ user.createdAt }</td>
                  <td><Button variant="outline-danger" size="sm" onClick={ () => this.resetPassword(user._id) }>重設密碼</Button></td>
                </tr>
              )) }
            </tbody>
          </table>
        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>
          <p className="center">使用者數量：0</p>
          <p className="center">查無使用者</p>
        </React.Fragment>
      );
    }
  }

  content = () => {
    const { isLoggedIn, data } = this.props.user;
    return (
      <React.Fragment>
        { (isLoggedIn && (data.admin > 0)) ? this.adminView() : <Navigate to="/error" /> }
      </React.Fragment>
    );
  }

  adminView = () => {
    return (
      <React.Fragment>
        { this.manageList() }
        <hr />
        { this.state.manageState > 0 ? this.manageView() : <p className="center">請選擇管理項目</p> }
      </React.Fragment>
    );
  }

  manageList = () => {
    return (
      <React.Fragment>
        <p>管理項目：</p>
        <Button variant="outline-success" onClick={ () => this.setState({ manageState: 1, activeState: [true, false, false, false, false, false, false, false, false, false, false, false], msg: "" }) } active={ this.state.activeState[0] }>網站</Button>
        <Button variant="outline-success" onClick={ () => this.setState({ manageState: 2, activeState: [false, true, false, false, false, false, false, false, false, false, false, false], msg: "" }) } active={ this.state.activeState[1] }>檔案上傳</Button>
        <Button variant="outline-success" onClick={ () => this.setState({ manageState: 3, activeState: [false, false, true, false, false, false, false, false, false, false, false, false], msg: "" }) } active={ this.state.activeState[2] }>所有檔案</Button>
        <Button variant="outline-success" onClick={ () => this.setState({ manageState: 4, activeState: [false, false, false, true, false, false, false, false, false, false, false, false], msg: "" }) } active={ this.state.activeState[3] }>所有交易</Button>
        <Button variant="outline-success" onClick={ () => this.setState({ manageState: 5, activeState: [false, false, false, false, true, false, false, false, false, false, false, false], msg: "" }) } active={ this.state.activeState[4] }>所有使用者</Button>
        <Button variant="outline-success" onClick={ () => this.setState({ manageState: 6, activeState: [false, false, false, false, false, true, false, false, false, false, false, false], msg: "" }) } active={ this.state.activeState[5] }>類型</Button>
        <Button variant="outline-success" onClick={ () => this.setState({ manageState: 7, activeState: [false, false, false, false, false, false, true, false, false, false, false, false], msg: "" }) } active={ this.state.activeState[6] }>年度</Button>
        <Button variant="outline-success" onClick={ () => this.setState({ manageState: 8, activeState: [false, false, false, false, false, false, false, true, false, false, false, false], msg: "" }) } active={ this.state.activeState[7] }>時段</Button>
        <Button variant="outline-success" onClick={ () => this.setState({ manageState: 9, activeState: [false, false, false, false, false, false, false, false, true, false, false, false], msg: "" }) } active={ this.state.activeState[8] }>年級</Button>
        <Button variant="outline-success" onClick={ () => this.setState({ manageState: 10, activeState: [false, false, false, false, false, false, false, false, false, true, false, false], msg: "" }) } active={ this.state.activeState[9] }>地區</Button>
        <Button variant="outline-success" onClick={ () => this.setState({ manageState: 11, activeState: [false, false, false, false, false, false, false, false, false, false, true, false], msg: "" }) } active={ this.state.activeState[10] }>格式</Button>
        <Button variant="outline-success" onClick={ () => this.setState({ manageState: 12, activeState: [false, false, false, false, false, false, false, false, false, false, false, true], msg: "" }) } active={ this.state.activeState[11] }>其他</Button>
      </React.Fragment>
    );
  }

  manageView = () => {
    const { manageState, msg } = this.state;
    if(manageState === 1) {
      return (
        <React.Fragment>
          <p className="center">網站管理</p>
          { msg.length > 0 ? <p className="center red">{ msg }</p> : null }
          <table className="center" style={{ margin: "0 auto" }}>
            <tbody>
              <tr>
                <th>名稱</th>
                <th>內容</th>
                <th>修改</th>
              </tr>
              <tr>
                <td>公告</td>
                <td><input type="text" value={ this.state.tmpNotes } onChange={ (e) => this.handleNotesChange(e) } disabled={ !this.state.changeNotes } /></td>
                <td>
                  <Button variant="outline-secondary" size="sm" onClick={ () => this.setState({ changeNotes: true }) } disabled={ this.state.changeNotes }>修改</Button>
                  <Button variant="outline-primary" size="sm" onClick={ () => this.updateNotes() } disabled={ !this.state.changeNotes }>確定</Button>
                  <Button variant="outline-danger" size="sm" onClick={ () => this.setState({ tmpNotes: this.props.site.notes, changeNotes: false }) } disabled={ !this.state.changeNotes }>取消</Button>
                </td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      );
    }
    else if(manageState === 2) {
      return (
        <React.Fragment>
          <p className="center">檔案上傳</p>
          { msg.length > 0 ? <p className="center red">{ msg }</p> : null }
          <form onSubmit={ (e) => this.uploadFile(e) }>
            <table style={{ margin: "0 auto" }}>
              <tbody>
                <tr>
                  <th>*選擇檔案</th>
                  <td><input type="file" name="file" onChange={ (e) => this.handleFileChange(e) } required /></td>
                </tr>
                <tr>
                  <th>*標題</th>
                  <td><input type="text" name="title" value={ this.state.fileTitle } onChange={ (e) => this.handleFileTitleChange(e) } required /></td>
                </tr>
                <tr>
                  <th>*類型</th>
                  <td>{ this.showTypes() }</td>
                </tr>
                <tr>
                  <th>年度</th>
                  <td>{ this.showYears() }</td>
                </tr>
                <tr>
                  <th>時段</th>
                  <td>{ this.showTimes() }</td>
                </tr>
                <tr>
                  <th>年級</th>
                  <td>{ this.showGrades() }</td>
                </tr>
                <tr>
                  <th>地區</th>
                  <td>{ this.showRegions() }</td>
                </tr>
                <tr>
                  <th>*格式</th>
                  <td>{ this.showFormats() }</td>
                </tr>
                <tr>
                  <th>其他</th>
                  <td>{ this.showOthers() }</td>
                </tr>
                <tr>
                  <th>確認上傳</th>
                  <td><Button variant="outline-primary" size="sm" type="submit" name="submit">上傳</Button></td>
                </tr>
              </tbody>
            </table>
          </form>
        </React.Fragment>
      );
    }
    else if(manageState === 3) {
      return (
        <React.Fragment>
          <p className="center">所有檔案</p>
          { msg.length > 0 ? <p className="center red">{ msg }</p> : null }
          { this.state.filesLoading ? <p className="center"><img src={ loadingGif } alt="loading" /></p> : this.showFiles() }
        </React.Fragment>
      );
    }
    else if(manageState === 4) {
      return (
        <React.Fragment>
          <p className="center">所有交易</p>
          { msg.length > 0 ? <p className="center red">{ msg }</p> : null }
          { this.state.ordersLoading ? <p className="center"><img src={ loadingGif } alt="loading" /></p> : this.showOrders() }
        </React.Fragment>
      );
    }
    else if(manageState === 5) {
      return (
        <React.Fragment>
          <p className="center">所有使用者</p>
          { msg.length > 0 ? <p className="center red">{ msg }</p> : null }
          { this.state.usersLoading ? <p className="center"><img src={ loadingGif } alt="loading" /></p> : this.showUsers() }
        </React.Fragment>
      );
    }
    else if(manageState === 6) {
      const { types } = this.props.ss;
      return (
        <React.Fragment>
          <p className="center">類型管理</p>
          <p className="center">若有特定順序請使用 <b>1.名稱 2.名稱</b> 格式</p>
          { msg.length > 0 ? <p className="center red">{ msg }</p> : null }
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>現有類型</td>
                <td>{ typeof types === "string" ? <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button> : types.map(type => (
                  <React.Fragment>
                    <Button variant="outline-secondary" size="sm">{ type.name }</Button>
                  </React.Fragment>
                )) }</td>
              </tr>
            </tbody>
          </table>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>新增類型</td>
                <td><input type="text" name="ntype" value={ this.state.ntype } onChange={ (e) => this.setState({ ntype: e.target.value }) } placeholder="輸入類型名稱" required={ true } /></td>
                <td><Button variant="outline-primary" size="sm" onClick={ () => this.addType() }>新增</Button></td>
              </tr>
            </tbody>
          </table>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td rowSpan="2">修改類型</td>
                <td><input type="text" name="etype" value={ this.state.etype } onChange={ (e) => this.setState({ etype: e.target.value }) } placeholder="輸入類型名稱" required={ true } /></td>
                <td rowSpan="2"><Button variant="outline-primary" size="sm" onClick={ () => this.editType() }>修改</Button></td>
              </tr>
              <tr>
                <td><input type="text" name="etypen" value={ this.state.etypen } onChange={ (e) => this.setState({ etypen: e.target.value }) } placeholder="輸入新類型名稱" required={ true } /></td>
              </tr>
            </tbody>
          </table>
          <hr />
          <p className="center red"><b>非必要情況請勿使用刪除，多利用修改。</b></p>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>刪除類型</td>
                <td><input type="text" name="dtype" value={ this.state.dtype } onChange={ (e) => this.setState({ dtype: e.target.value }) } placeholder="輸入類型名稱" required={ true } /></td>
                <td><Button variant="outline-danger" size="sm" onClick={ () => this.deleteType() }>刪除</Button></td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      );
    }
    else if(manageState === 7) {
      const { years } = this.props.ss;
      return (
        <React.Fragment>
          <p className="center">年度管理</p>
          <p className="center">若有特定順序請使用 <b>1.名稱 2.名稱</b> 格式</p>
          { msg.length > 0 ? <p className="center red">{ msg }</p> : null }
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>現有年度</td>
                <td>{ typeof years === "string" ? <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button> : years.map(year => (
                  <React.Fragment>
                    <Button variant="outline-secondary" size="sm">{ year.name }</Button>
                  </React.Fragment>
                )) }</td>
              </tr>
            </tbody>
          </table>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>新增年度</td>
                <td><input type="text" name="nyear" value={ this.state.nyear } onChange={ (e) => this.setState({ nyear: e.target.value }) } placeholder="輸入年度名稱" required={ true } /></td>
                <td><Button variant="outline-primary" size="sm" onClick={ () => this.addYear() }>新增</Button></td>
              </tr>
            </tbody>
          </table>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td rowSpan="2">修改年度</td>
                <td><input type="text" name="eyear" value={ this.state.eyear } onChange={ (e) => this.setState({ eyear: e.target.value }) } placeholder="輸入年度名稱" required={ true } /></td>
                <td rowSpan="2"><Button variant="outline-primary" size="sm" onClick={ () => this.editYear() }>修改</Button></td>
              </tr>
              <tr>
                <td><input type="text" name="eyearn" value={ this.state.eyearn } onChange={ (e) => this.setState({ eyearn: e.target.value }) } placeholder="輸入新年度名稱" required={ true } /></td>
              </tr>
            </tbody>
          </table>
          <hr />
          <p className="center red"><b>非必要情況請勿使用刪除，多利用修改。</b></p>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>刪除年度</td>
                <td><input type="text" name="dyear" value={ this.state.dyear } onChange={ (e) => this.setState({ dyear: e.target.value }) } placeholder="輸入年度名稱" required={ true } /></td>
                <td><Button variant="outline-danger" size="sm" onClick={ () => this.deleteYear() }>刪除</Button></td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      );
    }
    else if(manageState === 8) {
      const { times } = this.props.ss;
      return (
        <React.Fragment>
          <p className="center">時段管理</p>
          <p className="center">若有特定順序請使用 <b>1.名稱 2.名稱</b> 格式</p>
          { msg.length > 0 ? <p className="center red">{ msg }</p> : null }
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>現有時段</td>
                <td>{ typeof times === "string" ? <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button> : times.map(time => (
                  <React.Fragment>
                    <Button variant="outline-secondary" size="sm">{ time.name }</Button>
                  </React.Fragment>
                )) }</td>
              </tr>
            </tbody>
          </table>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>新增時段</td>
                <td><input type="text" name="ntime" value={ this.state.ntime } onChange={ (e) => this.setState({ ntime: e.target.value }) } placeholder="輸入時段名稱" required={ true } /></td>
                <td><Button variant="outline-primary" size="sm" onClick={ () => this.addTime() }>新增</Button></td>
              </tr>
            </tbody>
          </table>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td rowSpan="2">修改時段</td>
                <td><input type="text" name="etime" value={ this.state.etime } onChange={ (e) => this.setState({ etime: e.target.value }) } placeholder="輸入時段名稱" required={ true } /></td>
                <td rowSpan="2"><Button variant="outline-primary" size="sm" onClick={ () => this.editTime() }>修改</Button></td>
              </tr>
              <tr>
                <td><input type="text" name="etimen" value={ this.state.etimen } onChange={ (e) => this.setState({ etimen: e.target.value }) } placeholder="輸入新時段名稱" required={ true } /></td>
              </tr>
            </tbody>
          </table>
          <hr />
          <p className="center red"><b>非必要情況請勿使用刪除，多利用修改。</b></p>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>刪除時段</td>
                <td><input type="text" name="dtime" value={ this.state.dtime } onChange={ (e) => this.setState({ dtime: e.target.value }) } placeholder="輸入時段名稱" required={ true } /></td>
                <td><Button variant="outline-danger" size="sm" onClick={ () => this.deleteTime() }>刪除</Button></td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      );
    }
    else if(manageState === 9) {
      const { grades } = this.props.ss;
      return (
        <React.Fragment>
          <p className="center">年級管理</p>
          <p className="center">若有特定順序請使用 <b>1.名稱 2.名稱</b> 格式</p>
          { msg.length > 0 ? <p className="center red">{ msg }</p> : null }
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>現有年級</td>
                <td>{ typeof grades === "string" ? <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button> : grades.map(grade => (
                  <React.Fragment>
                    <Button variant="outline-secondary" size="sm">{ grade.name }</Button>
                  </React.Fragment>
                )) }</td>
              </tr>
            </tbody>
          </table>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>新增年級</td>
                <td><input type="text" name="ngrade" value={ this.state.ngrade } onChange={ (e) => this.setState({ ngrade: e.target.value }) } placeholder="輸入年級名稱" required={ true } /></td>
                <td><Button variant="outline-primary" size="sm" onClick={ () => this.addGrade() }>新增</Button></td>
              </tr>
            </tbody>
          </table>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td rowSpan="2">修改年級</td>
                <td><input type="text" name="egrade" value={ this.state.egrade } onChange={ (e) => this.setState({ egrade: e.target.value }) } placeholder="輸入年級名稱" required={ true } /></td>
                <td rowSpan="2"><Button variant="outline-primary" size="sm" onClick={ () => this.editGrade() }>修改</Button></td>
              </tr>
              <tr>
                <td><input type="text" name="egraden" value={ this.state.egraden } onChange={ (e) => this.setState({ egraden: e.target.value }) } placeholder="輸入新年級名稱" required={ true } /></td>
              </tr>
            </tbody>
          </table>
          <hr />
          <p className="center red"><b>非必要情況請勿使用刪除，多利用修改。</b></p>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>刪除年級</td>
                <td><input type="text" name="dgrade" value={ this.state.dgrade } onChange={ (e) => this.setState({ dgrade: e.target.value }) } placeholder="輸入年級名稱" required={ true } /></td>
                <td><Button variant="outline-danger" size="sm" onClick={ () => this.deleteGrade() }>刪除</Button></td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      );
    }
    else if(manageState === 10) {
      const { regions } = this.props.ss;
      return (
        <React.Fragment>
          <p className="center">地區管理</p>
          <p className="center">若有特定順序請使用 <b>1.名稱 2.名稱</b> 格式</p>
          { msg.length > 0 ? <p className="center red">{ msg }</p> : null }
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>現有地區</td>
                <td>{ typeof regions === "string" ? <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button> : regions.map(region => (
                  <React.Fragment>
                    <Button variant="outline-secondary" size="sm">{ region.name }</Button>
                  </React.Fragment>
                )) }</td>
              </tr>
            </tbody>
          </table>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>新增地區</td>
                <td><input type="text" name="nregion" value={ this.state.nregion } onChange={ (e) => this.setState({ nregion: e.target.value }) } placeholder="輸入地區名稱" required={ true } /></td>
                <td><Button variant="outline-primary" size="sm" onClick={ () => this.addRegion() }>新增</Button></td>
              </tr>
            </tbody>
          </table>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td rowSpan="2">修改地區</td>
                <td><input type="text" name="eregion" value={ this.state.eregion } onChange={ (e) => this.setState({ eregion: e.target.value }) } placeholder="輸入地區名稱" required={ true } /></td>
                <td rowSpan="2"><Button variant="outline-primary" size="sm" onClick={ () => this.editRegion() }>修改</Button></td>
              </tr>
              <tr>
                <td><input type="text" name="eregionn" value={ this.state.eregionn } onChange={ (e) => this.setState({ eregionn: e.target.value }) } placeholder="輸入新地區名稱" required={ true } /></td>
              </tr>
            </tbody>
          </table>
          <hr />
          <p className="center red"><b>非必要情況請勿使用刪除，多利用修改。</b></p>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>刪除地區</td>
                <td><input type="text" name="dregion" value={ this.state.dregion } onChange={ (e) => this.setState({ dregion: e.target.value }) } placeholder="輸入地區名稱" required={ true } /></td>
                <td><Button variant="outline-danger" size="sm" onClick={ () => this.deleteRegion() }>刪除</Button></td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      );
    }
    else if(manageState === 11) {
      const { formats } = this.props.ss;
      return (
        <React.Fragment>
          <p className="center">格式管理</p>
          <p className="center">若有特定順序請使用 <b>1.名稱 2.名稱</b> 格式</p>
          { msg.length > 0 ? <p className="center red">{ msg }</p> : null }
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>現有格式</td>
                <td>{ typeof formats === "string" ? <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button> : formats.map(format => (
                  <React.Fragment>
                    <Button variant="outline-secondary" size="sm">{ format.name + " $" + format.price }</Button>
                  </React.Fragment>
                )) }</td>
              </tr>
            </tbody>
          </table>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td rowSpan="2">新增格式</td>
                <td><input type="type" name="nformat" value={ this.state.nformat } onChange={ (e) => this.setState({ nformat: e.target.value }) } placeholder="輸入格式名稱" required={ true } /></td>
                <td rowSpan="2"><Button variant="outline-primary" size="sm" onClick={ () => this.addFormat() }>新增</Button></td>
              </tr>
              <tr>
                <td><input type="number" name="nformatp" value={ this.state.nformatp } onChange={ (e) => this.setState({ nformatp: e.target.value }) } placeholder="輸入格式價格" required={ true } /></td>
              </tr>
            </tbody>
          </table>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td rowSpan="3">修改格式</td>
                <td><input type="type" name="eformat" value={ this.state.eformat } onChange={ (e) => this.setState({ eformat: e.target.value }) } placeholder="輸入格式名稱" required={ true } /></td>
                <td rowSpan="3"><Button variant="outline-primary" size="sm" onClick={ () => this.editFormat() }>修改</Button></td>
              </tr>
              <tr>
                <td><input type="type" name="eformatn" value={ this.state.eformatn } onChange={ (e) => this.setState({ eformatn: e.target.value }) } placeholder="輸入格式新名稱" required={ true } /></td>
              </tr>
              <tr>
                <td><input type="number" name="eformatp" value={ this.state.eformatp } onChange={ (e) => this.setState({ eformatp: e.target.value }) } placeholder="輸入格式新價格" required={ true } /></td>
              </tr>
            </tbody>
          </table>
          <hr />
          <p className="center red"><b>非必要情況請勿使用刪除，多利用修改。</b></p>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>刪除格式</td>
                <td><input type="text" name="dformat" value={ this.state.dformat } onChange={ (e) => this.setState({ dformat: e.target.value }) } placeholder="輸入格式名稱" required={ true } /></td>
                <td><Button variant="outline-danger" size="sm" onClick={ () => this.deleteFormat() }>刪除</Button></td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      );
    }
    else if(manageState === 12) {
      const { others } = this.props.ss;
      return (
        <React.Fragment>
          <p className="center">其他管理</p>
          <p className="center">若有特定順序請使用 <b>1.名稱 2.名稱</b> 格式</p>
          { msg.length > 0 ? <p className="center red">{ msg }</p> : null }
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>現有其他</td>
                <td>{ typeof others === "string" ? <Button variant="outline-secondary" size="sm" disabled={ true }>無</Button> : others.map(other => (
                  <React.Fragment>
                    <Button variant="outline-secondary" size="sm">{ other.name }</Button>
                  </React.Fragment>
                )) }</td>
              </tr>
            </tbody>
          </table>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>新增其他</td>
                <td><input type="text" name="nother" value={ this.state.nother } onChange={ (e) => this.setState({ nother: e.target.value }) } placeholder="輸入其他名稱" required={ true } /></td>
                <td><Button variant="outline-primary" size="sm" onClick={ () => this.addOther() }>新增</Button></td>
              </tr>
            </tbody>
          </table>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td rowSpan="2">修改其他</td>
                <td><input type="text" name="eother" value={ this.state.eother } onChange={ (e) => this.setState({ eother: e.target.value }) } placeholder="輸入其他名稱" required={ true } /></td>
                <td rowSpan="2"><Button variant="outline-primary" size="sm" onClick={ () => this.editOther() }>修改</Button></td>
              </tr>
              <tr>
                <td><input type="text" name="eothern" value={ this.state.eothern } onChange={ (e) => this.setState({ eothern: e.target.value }) } placeholder="輸入新其他名稱" required={ true } /></td>
              </tr>
            </tbody>
          </table>
          <hr />
          <p className="center red"><b>非必要情況請勿使用刪除，多利用修改。</b></p>
          <table className="center" style={{ margin: "20px auto" }}>
            <tbody>
              <tr>
                <td>刪除其他</td>
                <td><input type="text" name="dother" value={ this.state.dother } onChange={ (e) => this.setState({ dother: e.target.value }) } placeholder="輸入其他名稱" required={ true } /></td>
                <td><Button variant="outline-danger" size="sm" onClick={ () => this.deleteOther() }>刪除</Button></td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      );
    }
    else if(manageState === 13) {
      return (
        <React.Fragment>
          <p className="center">檔案修改</p>
          { msg.length > 0 ? <p className="center red">{ msg }</p> : null }
          <form onSubmit={ (e) => this.editFile(e) }>
            <table style={{ margin: "0 auto" }}>
              <tbody>
                <tr>
                  <th>選擇檔案（可不上傳，若上傳會取代原始檔案。）</th>
                  <td><input type="file" name="file" onChange={ (e) => this.setState({ editFileSelected: e.target.files[0] }) } /></td>
                </tr>
                <tr>
                  <th>*標題</th>
                  <td><input type="text" name="title" value={ this.state.editFileTitle } onChange={ (e) => this.setState({ editFileTitle: e.target.value }) } required /></td>
                </tr>
                <tr>
                  <th>*類型</th>
                  <td>{ this.showTypes() }</td>
                </tr>
                <tr>
                  <th>年度</th>
                  <td>{ this.showYears() }</td>
                </tr>
                <tr>
                  <th>時段</th>
                  <td>{ this.showTimes() }</td>
                </tr>
                <tr>
                  <th>年級</th>
                  <td>{ this.showGrades() }</td>
                </tr>
                <tr>
                  <th>地區</th>
                  <td>{ this.showRegions() }</td>
                </tr>
                <tr>
                  <th>*格式</th>
                  <td>{ this.showFormats() }</td>
                </tr>
                <tr>
                  <th>其他</th>
                  <td>{ this.showOthers() }</td>
                </tr>
                <tr>
                  <th>重選</th>
                  <td><Button variant="outline-danger" size="sm" onClick={ () => this.setState({ type: "", year: "", time: "", grade: "", region: "", format: "", other: "" }) }>重選</Button></td>
                </tr>
                <tr>
                  <th>確認修改</th>
                  <td><Button variant="outline-primary" size="sm" type="submit" name="submit">修改</Button></td>
                </tr>
              </tbody>
            </table>
          </form>
        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>
          <p className="center">系統忙碌中，請稍候再試。</p>
        </React.Fragment>
      );
    }
  }

  handleNotesChange = (e) => {
    const tmpNotes = e.target.value;
    this.setState({ tmpNotes });
  }

  updateNotes = () => {
    if(this.state.tmpNotes === this.props.site.notes) {
      this.setState({ changeNotes: false });
      return;
    }
    const options = {
      url: "/api/site/updatenotes",
      method: "POST",
      data: {
        value: this.state.tmpNotes.length > 0 ? this.state.tmpNotes : " "
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => this.setState({ msg: res.data }))
      .catch((err) => this.setState({ msg: err.response.data }));
    this.props.site.notes = this.state.tmpNotes.length > 0 ? this.state.tmpNotes : " ";
    if(this.state.tmpNotes.length === 0) {
      this.setState({ tmpNotes: " " });
    }
    this.setState({ changeNotes: false });
  }

  handleFileChange = (e) => {
    this.setState({ selectedFile: e.target.files[0], fileTitle: e.target.files[0].name });
  }

  handleFileTitleChange = (e) => {
    const fileTitle = e.target.value;
    this.setState({ fileTitle });
  }

  uploadFile = (e) => {
    e.preventDefault();
    if(!this.state.selectedFile) {
      alert("請選擇檔案。");
      return;
    }
    if(this.state.fileTitle.length === 0) {
      alert("請輸入標題。");
      return;
    }
    if(this.state.type.length === 0) {
      alert("請選擇類型。");
      return;
    }
    if(this.state.format.length === 0) {
      alert("請選擇格式。");
      return;
    }
    const formData = new FormData();
    formData.append("file", this.state.selectedFile);
    formData.append("title", this.state.fileTitle);
    formData.append("type", this.state.type);
    if(this.state.year.length > 0) {
      formData.append("year", this.state.year);
    }
    if(this.state.time.length > 0) {
      formData.append("time", this.state.time);
    }
    if(this.state.grade.length > 0) {
      formData.append("grade", this.state.grade);
    }
    if(this.state.region.length > 0) {
      formData.append("region", this.state.region);
    }
    formData.append("format", this.state.format);
    if(this.state.other.length > 0) {
      formData.append("other", this.state.other);
    }
    const options = {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      url: "/api/file/uploadfile",
      method: "POST",
      data: formData,
      withCredentials: true
    };
    axios(options)
      .then((res) => this.setState({ msg: res.data, filesLoading: true }))
      .catch((err) => this.setState({ msg: err.response.data }));
    e.target.file.value = null;
    this.setState({
      selectedFile: null,
      fileTitle: "",
      type: "",
      year: "",
      time: "",
      grade: "",
      region: "",
      format: "",
      other: ""
    });
  }

  editFile = (e) => {
    e.preventDefault();
    if(this.state.editFileTitle.length === 0) {
      alert("請輸入標題。");
      return;
    }
    if(this.state.type.length === 0) {
      alert("請選擇類型。");
      return;
    }
    if(this.state.format.length === 0) {
      alert("請選擇格式。");
      return;
    }
    const formData = new FormData();
    if(this.state.editFileSelected) {
      formData.append("file", this.state.editFileSelected);
    }
    formData.append("title", this.state.editFileTitle);
    formData.append("type", this.state.type);
    if(this.state.year.length > 0) {
      formData.append("year", this.state.year);
    }
    if(this.state.time.length > 0) {
      formData.append("time", this.state.time);
    }
    if(this.state.grade.length > 0) {
      formData.append("grade", this.state.grade);
    }
    if(this.state.region.length > 0) {
      formData.append("region", this.state.region);
    }
    formData.append("format", this.state.format);
    if(this.state.other.length > 0) {
      formData.append("other", this.state.other);
    }
    formData.append("fileID", this.state.editFile);
    const options = {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      url: "/api/file/editfile",
      method: "POST",
      data: formData,
      withCredentials: true
    };
    axios(options)
      .then((res) => this.setState({ msg: res.data, filesLoading: true, manageState: 3 }))
      .catch((err) => this.setState({ msg: err.response.data }));
    e.target.file.value = null;
    this.setState({
      editFileSelected: null,
      editFileTitle: "",
      type: "",
      year: "",
      time: "",
      grade: "",
      region: "",
      format: "",
      other: ""
    });
  }

  resetPassword = (id) => {
    const options = {
      url: "/api/user/resetpassword",
      method: "POST",
      data: {
        _id: id
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.setState({ msg: res });
      })
      .catch((err) => this.setState({ msg: err.response.data, ntype: "" }));
  }

  addType = () => {
    if(this.state.ntype.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    const options = {
      url: "/api/ss/addtype",
      method: "POST",
      data: {
        name: this.state.ntype
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSTypes());
        this.setState({ msg: res, ntype: "" });
      })
      .catch((err) => this.setState({ msg: err.response.data, ntype: "" }));
  }

  editType = () => {
    if(this.state.etype.length === 0 || this.state.etypen.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    const options = {
      url: "/api/ss/edittype",
      method: "POST",
      data: {
        originalName: this.state.etype,
        name: this.state.etypen
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSTypes());
        this.setState({ msg: res, etype: "", etypen: "" });
      })
      .catch((err) => this.setState({ msg: err.response.data, etype: "", etypen: "" }));
  }

  deleteType = () => {
    if(this.state.dtype.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    const options = {
      url: "/api/ss/deletetype",
      method: "POST",
      data: {
        name: this.state.dtype
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSTypes());
        this.setState({ msg: res, dtype: "" });
      })
      .catch((err) => this.setState({ msg: err.response.data, dtype: "" }));
  }

  addYear = () => {
    if(this.state.nyear.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    const options = {
      url: "/api/ss/addyear",
      method: "POST",
      data: {
        name: this.state.nyear
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSYears());
        this.setState({ msg: res, nyear: "" });
      })
      .catch((err) => this.setState({ msg: err.response.data, nyear: "" }));
  }

  editYear = () => {
    if(this.state.eyear.length === 0 || this.state.eyearn.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    const options = {
      url: "/api/ss/edityear",
      method: "POST",
      data: {
        originalName: this.state.eyear,
        name: this.state.eyearn
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSYears());
        this.setState({ msg: res, eyear: "", eyearn: "" });
      })
      .catch((err) => this.setState({ msg: err.response.data, eyear: "", eyearn: "" }));
  }

  deleteYear = () => {
    if(this.state.dyear.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    const options = {
      url: "/api/ss/deleteyear",
      method: "POST",
      data: {
        name: this.state.dyear
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSYears());
        this.setState({ msg: res, dyear: "" });
      })
      .catch((err) => this.setState({ msg: err.response.data, dyear: "" }));
  }

  addTime = () => {
    if(this.state.ntime.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    const options = {
      url: "/api/ss/addtime",
      method: "POST",
      data: {
        name: this.state.ntime
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSTimes());
        this.setState({ msg: res, ntime: "" });
      })
      .catch((err) => this.setState({ msg: err.response.data, ntime: "" }));
  }

  editTime = () => {
    if(this.state.etime.length === 0 || this.state.etimen.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    const options = {
      url: "/api/ss/edittime",
      method: "POST",
      data: {
        originalName: this.state.etime,
        name: this.state.etimen
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSTimes());
        this.setState({ msg: res, etime: "", etimen: "" });
      })
      .catch((err) => this.setState({ msg: err.response.data, etime: "", etimen: "" }));
  }

  deleteTime = () => {
    if(this.state.dtime.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    const options = {
      url: "/api/ss/deletetime",
      method: "POST",
      data: {
        name: this.state.dtime
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSTimes());
        this.setState({ msg: res, dtime: "" });
      })
      .catch((err) => this.setState({ msg: err.response.data, dtime: "" }));
  }

  addGrade = () => {
    if(this.state.ngrade.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    const options = {
      url: "/api/ss/addgrade",
      method: "POST",
      data: {
        name: this.state.ngrade
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSGrades());
        this.setState({ msg: res, ngrade: "" });
      })
      .catch((err) => this.setState({ msg: err.response.data, ngrade: "" }));
  }

  editGrade = () => {
    if(this.state.egrade.length === 0 || this.state.egraden.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    const options = {
      url: "/api/ss/editgrade",
      method: "POST",
      data: {
        originalName: this.state.egrade,
        name: this.state.egraden
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSGrades());
        this.setState({ msg: res, egrade: "", egraden: "" });
      })
      .catch((err) => this.setState({ msg: err.response.data, egrade: "", egraden: "" }));
  }

  deleteGrade = () => {
    if(this.state.dgrade.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    const options = {
      url: "/api/ss/deletegrade",
      method: "POST",
      data: {
        name: this.state.dgrade
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSGrades());
        this.setState({ msg: res, dgrade: "" });
      })
      .catch((err) => this.setState({ msg: err.response.data, dgrade: "" }));
  }

  addRegion = () => {
    if(this.state.nregion.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    const options = {
      url: "/api/ss/addregion",
      method: "POST",
      data: {
        name: this.state.nregion
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSRegions());
        this.setState({ msg: res, nregion: "" });
      })
      .catch((err) => this.setState({ msg: err.response.data, nregion: "" }));
  }

  editRegion = () => {
    if(this.state.eregion.length === 0 || this.state.eregionn.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    const options = {
      url: "/api/ss/editregion",
      method: "POST",
      data: {
        originalName: this.state.eregion,
        name: this.state.eregionn
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSRegions());
        this.setState({ msg: res, eregion: "", eregionn: "" });
      })
      .catch((err) => this.setState({ msg: err.response.data, eregion: "", eregionn: "" }));
  }

  deleteRegion = () => {
    if(this.state.dregion.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    const options = {
      url: "/api/ss/deleteregion",
      method: "POST",
      data: {
        name: this.state.dregion
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSRegions());
        this.setState({ msg: res, dregion: "" });
      })
      .catch((err) => this.setState({ msg: err.response.data, dregion: "" }));
  }

  addFormat = () => {
    if(this.state.nformat.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    if(this.state.nformatp < 0) {
      this.setState({ msg: "Price must be at least 0."});
      return;
    }
    const options = {
      url: "/api/ss/addformat",
      method: "POST",
      data: {
        name: this.state.nformat,
        price: this.state.nformatp
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSFormats());
        this.setState({ msg: res, nformat: "", nformatp: 0 });
      })
      .catch((err) => this.setState({ msg: err.response.data, nformat: "", nformatp: 0 }));
  }

  editFormat = () => {
    if(this.state.eformat.length === 0 || this.state.eformatn.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    if(this.state.eformatp < 0) {
      this.setState({ msg: "Price must be at least 0."});
      return;
    }
    const options = {
      url: "/api/ss/editformat",
      method: "POST",
      data: {
        originalName: this.state.eformat,
        name: this.state.eformatn,
        price: this.state.eformatp
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSFormats());
        this.setState({ msg: res, eformat: "", eformatn: "", eformatp: 0 });
      })
      .catch((err) => this.setState({ msg: err.response.data, eformat: "", eformatn: "", eformatp: 0 }));
  }

  deleteFormat = () => {
    if(this.state.dformat.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    const options = {
      url: "/api/ss/deleteformat",
      method: "POST",
      data: {
        name: this.state.dformat
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSFormats());
        this.setState({ msg: res, dformat: "" });
      })
      .catch((err) => this.setState({ msg: err.response.data, dformat: "" }));
  }

  addOther = () => {
    if(this.state.nother.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    const options = {
      url: "/api/ss/addother",
      method: "POST",
      data: {
        name: this.state.nother
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSOthers());
        this.setState({ msg: res, nother: "" });
      })
      .catch((err) => this.setState({ msg: err.response.data, nother: "" }));
  }

  editOther = () => {
    if(this.state.eother.length === 0 || this.state.eothern.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    const options = {
      url: "/api/ss/editother",
      method: "POST",
      data: {
        originalName: this.state.eother,
        name: this.state.eothern
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSOthers());
        this.setState({ msg: res, eother: "", eothern: "" });
      })
      .catch((err) => this.setState({ msg: err.response.data, eother: "", eothern: "" }));
  }

  deleteOther = () => {
    if(this.state.dother.length === 0) {
      this.setState({ msg: "Name cannot be empty." });
      return;
    }
    const options = {
      url: "/api/ss/deleteother",
      method: "POST",
      data: {
        name: this.state.dother
      },
      withCredentials: true
    };
    axios(options)
      .then((res) => res.data)
      .then((res) => {
        this.props.dispatch(getSSOthers());
        this.setState({ msg: res, dother: "" });
      })
      .catch((err) => this.setState({ msg: err.response.data, dother: "" }));
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div className="manage">
        <Helmet>
          <title>{ settings.PROJECTNAME } - 管理</title>
        </Helmet>
        { isLoading ? <img src={ loadingGif } alt="loading" /> : this.content() }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    site: state.site,
    ss: state.ss
  };
}

export default connect(mapStateToProps)(Manage);
