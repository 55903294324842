import { combineReducers } from 'redux';

import user from './userReducer';
import site from './siteReducer';
import ss from './ssReducer';

const rootReducer = combineReducers({
  user,
  site,
  ss
});

export default rootReducer;
