import * as act from '../actions';

const initialState = {
  isLoading: true,
  notes: ""
};

const site = (state = initialState, action) => {
  switch(action.type) {
    case act.GETSITENOTES:
      return {
        ...state,
        isLoading: false,
        notes: action.payload
      };
    default:
      return state;
  }
};

export default site;
